import { Box, Button, ButtonBase, CircularProgress, IconButton, InputAdornment, Modal, styled, TextField, Typography } from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import close from "../../../../assets/img/views/settings/Close.svg";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import useAsyncState, { isFulfilled, isPending, isUnloaded } from "src/utils/Async";
import { SupabaseCall, toHHMMSS } from "src/utils/common";
import { Tables } from "src/utils/DatabaseDefinitions";
import { useSnackbar } from "notistack";

export type AssignWorkoutModalProps = {
  open: boolean;
  athleteId: string;
  onCancel: () => void;
  date: Date
  onApply?: (assignedWorkout: Tables<"assigned_workout">) => void
};

export type FormikProps = {
	name: string;
	duration: number;
	distance: number;
	tss: number;
	position_goal: string;
	bike_type: string;
	workout_type: string;
};

const SearchInputWrapper = styled(TextField)(
    ({ theme }) => `
          border-radius: ${theme.general.borderRadius};
        `,
  );

  
export default function AssignWorkoutModal(props: AssignWorkoutModalProps) {
  const { open, onCancel, date, athleteId, onApply } = props;
  const supabase = useSupabase()
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState("")
  const [selectedWorkout, setSelectedWorkout] = useState<Tables<"workout"> & {workout_step: Tables<"workout_step">[]}>()
  const workoutsPromise = useCallback(
    () =>
      supabase
        .from("workout")
        .select("*, workout_step(*)")
        .eq("user_id", user.id)
    ,[supabase],
  );

  const workoutsCall =
  useAsyncState<SupabaseCall<typeof workoutsPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(workoutsCall)) {
      workoutsCall.fire(async () => workoutsPromise());
    }
  }, [supabase, workoutsCall]);

  const workouts = useMemo(
    () => {
      if(isFulfilled(workoutsCall)) {
        return workoutsCall.result.data.filter((workout) => workout.name.toLowerCase().includes(search.toLowerCase()))
      }
      return []
    },
    [workoutsCall, search],
  );
  const applyWorkoutPromise = useCallback(
        (values: FormikProps) =>
            supabase
                .from("assigned_workout")
                .insert({
                    date: dayjs(date).toISOString(),
                    name: values.name,
                    workout_type: values.workout_type,
                    bike_type: values.bike_type,
                    duration: values.duration,
                    distance: values.distance,
                    tss: values.tss,
                    position_goal: values.position_goal,
                    user_id: athleteId,
                    coach_id: user.user_metadata.accountType === "coach" ?  user.id : undefined
                })
                .select("*")
                .throwOnError()
                .then(async ({ data }) => {
                  await supabase
                  .from("assigned_workout_step")
                  .insert(
                      selectedWorkout.workout_step.map((workout_step, i) => {
                          //@ts-ignore
                          delete workout_step.workout_id;
                          delete workout_step.id;
                          return {
                              ...workout_step,
                              assigned_workout_id: data[0].id,
                          };
                      }),
                  )
                  .throwOnError()
                  return data[0]
                }
                ),
        [supabase, selectedWorkout, date, athleteId, user],
    );

    const applyWorkoutCall =
        useAsyncState<SupabaseCall<typeof applyWorkoutPromise>>();

    useEffect(() => {
        if (isFulfilled(applyWorkoutCall)) {
            enqueueSnackbar("Workout applied succesfully!", {
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "top",
                },
                variant: "success",
                autoHideDuration: 2000,
            });
            if(onApply && applyWorkoutCall.result) onApply(applyWorkoutCall.result)
            applyWorkoutCall.unload()
            onCancel()
        }
    }, [applyWorkoutCall, onApply]);

  return (
    <Modal open={open} onClose={onCancel} style={{ backdropFilter: "blur(5px)" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#2A252B",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{px: 3, pt:3, pb: 1}}>
            <IconButton
                onClick={(event) => {
                        event.preventDefault();
                        onCancel();
                    }}
                sx={{
                    position: "absolute",
                    top: 4,
                    right: 4
                }}
                >
                    <img src={close} alt="close" />
            </IconButton>
            <Typography sx={{fontSize: "15px"}}>
            Select Workout
            </Typography>
            <Typography sx={{fontSize: "10px", fontWeight: 400, opacity: "0.5"}}>
            {dayjs(date).format("ddd, DD, MMM")}
            </Typography>
            <SearchInputWrapper
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon sx={{ opacity: "0.5" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                marginTop: "10px",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#DD4F4A",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#DD4F4A",
                },
              }}
              placeholder="Search workout"
              onKeyDown={(e) => e.code === "Enter"}
              onChange={(e) => setSearch(e.target.value)}
            />
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", height: "300px", overflow: "auto",  "&::-webkit-scrollbar": {display: "none"}}}>
              {
                workouts.map((workout) => (
                    <ButtonBase key={workout.id} onClick={() => setSelectedWorkout(workout)} sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", bgcolor: selectedWorkout?.id === workout.id ? "#262026" : undefined, px: 3, py: 1, "&:hover": {bgcolor: "#262026"}}}>
                        <Typography sx={{fontSize: "14px", fontWeight: 400}}>
                            {workout.name}
                        </Typography>
                        <Typography sx={{fontSize: "10px", fontWeight: 400, opacity: "0.5"}}>
                            {toHHMMSS(workout.duration)}, {workout.tss} TSS, {workout.workout_type}
                        </Typography>
                    </ButtonBase>
                ))
              }
        </Box>

          <Button
            onClick={() => {
                if ("fire" in applyWorkoutCall) {
                    applyWorkoutCall.fire(async () =>
                        applyWorkoutPromise(selectedWorkout),
                    );
                }
            }}
            disabled={isPending(applyWorkoutCall)}
            variant="coachVariant"
            size="small"
            sx={{ width: "150px", my: "20px", alignSelf: "center" }}
            color="error"
          >
            {
              isPending(applyWorkoutCall) ? (
                <CircularProgress size={20} thickness={3} style={{color: "#FFF"}}/>
              ) : "Apply"
            }
          </Button>
      </Box>
    </Modal>
  );
}