import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DashboardCard from "../customComponents/DashboardCard";
import { useTitle } from "../customLayouts/DashboardLayout";
import { useEffect, useCallback, useState, useMemo } from "react";
import MyRidesTabs from "../customComponents/dashboard-widgets/MyRidesTabs";
import CalendarWidget from "../customComponents/dashboard-widgets/CalendarWidget";
import PerformanceProgressWidget from "../customComponents/dashboard-widgets/PerformanceProgressWidget";
import { useSupabase } from "src/contexts/SupabaseContext";
import type { SupabaseCall } from "src/utils/common";
import useAsyncState, {
  type AsyncState,
  isFulfilled,
  isUnloaded,
} from "src/utils/Async";
import CloseIcon from "@mui/icons-material/Close";
import MyRidesCardEmpty from "../customComponents/dashboard-widgets/MyRidesCardEmpty";
import TrendsWidgetEmpty from "../customComponents/dashboard-widgets/TrendsWidgetEmpty";
import dayjs from "dayjs";
import PositionProgressWidgetTabs from "../customComponents/dashboard-widgets/PositionProgressWidgetTabsCard";
import { WithCache } from "src/contexts/CacheContext";
import TrendsWidget from "../customComponents/dashboard-widgets/TrendsWidget";
import { useUser } from "src/components/Authenticated";
import type { Database, Json, Tables } from "src/utils/DatabaseDefinitions";
import LockScreenWidget from "../customComponents/dashboard-widgets/LockScreenWidget";

export type SessionType = Database["public"]["Tables"]["session"]["Row"] & {
  ride_types: Tables<'ride_types'>
  session_statistics: (Tables<'session_statistics'> & {
    custom_positions: Tables<'custom_positions'>, 
    default_positions: Tables<'default_positions'>
  })[]
};

export default function DashboardView() {
  const [, setTitle] = useTitle();
  const user = useUser();
  const supabase = useSupabase();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);
  const rangeHigh = dayjs();
  const rangeLow = rangeHigh.subtract(5, "month");
  const trendsRangeLow = rangeHigh.subtract(365, "days");
  const currentDate = dayjs();
  const startOfWeek = useMemo(
    () =>
      currentDate.day() === 0
        ? currentDate.subtract(1, "day").day(1).startOf("day")
        : currentDate.day(1).startOf("day"),
    [],
  );
  const endOfWeek = useMemo(() => startOfWeek.endOf("week").add(1, "day"), []);

  const subscriptionPromise = useCallback(
    () =>
      supabase
        .from("athlete_subscription")
        .select("*")
        .eq("athlete_id", user.id)
        .then((res) => res.data[0]),
    [supabase],
  );

  const subscriptionCall =
    useAsyncState<SupabaseCall<typeof subscriptionPromise>>();

  // Loading effect
  useEffect(() => {
    if (isUnloaded(subscriptionCall)) {
      subscriptionCall.fire(async () => subscriptionPromise());
    }
  }, [subscriptionCall, subscriptionPromise]);

  const isPremium = useMemo(() => {
    if (isFulfilled(subscriptionCall)) {
      return subscriptionCall?.result?.status === "active";
    }
  }, [subscriptionCall]);
  const profileSettingsPromise = useCallback(
    () =>
      supabase
        .from("athlete")
        .select()
        .eq("user_id", user.id)
        .then((res) => res.data[0]),
    [supabase],
  );

  const storedProfileSettingsCall =
    useAsyncState<SupabaseCall<typeof profileSettingsPromise>>();
  // Loading effect
  useEffect(() => {
    if (isUnloaded(storedProfileSettingsCall)) {
      storedProfileSettingsCall.fire(async () => profileSettingsPromise());
    }
  }, [storedProfileSettingsCall, profileSettingsPromise]);

  useEffect(() => {
    if (isFulfilled(storedProfileSettingsCall)) {
      setTitle(`Welcome, ${storedProfileSettingsCall.result.name}`);
    }
  }, [storedProfileSettingsCall]);

  //Snackbar

  const noDataPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select("*")
        .eq("athlete_id", user.id)
        .limit(1)
        .then((res) => res.data),
    [supabase],
  );

  const noDataCall = useAsyncState<SupabaseCall<typeof noDataPromise>>();

  // Loading effect
  useEffect(() => {
    if (isUnloaded(noDataCall)) {
      noDataCall.fire(async () => noDataPromise());
    }
  }, [noDataCall, noDataPromise]);

  useEffect(() => {
    if (isFulfilled(noDataCall)) {
      if (noDataCall.result.length === 0) setSnackbarOpen(true);
    }
  }, [noDataCall]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // My Rides Call
  // Supabase Promise for Sessions (Last two)
  const [lastSessionData, setLastSessionData] = useState<SessionType>();
  const [previousSessionData, setPreviousSessionData] = useState<SessionType>();
  const [sessionsLength, setSessionsLength] = useState<number>(0);
  const supabasePromise = useCallback(
    () =>
      supabase
        .from("session")
        .select("*,ride_types!inner(*),session_statistics(*,default_positions(*),custom_positions(*))")
        .eq("athlete_id", user.id)
        .order("date", { ascending: false })
        .order("position_percentage", { ascending: false,foreignTable: "session_statistics" })
        .limit(2)
        .then((res) => {
          setLastSessionData(res.data[0]);
          setPreviousSessionData(res.data[1]);
          setSessionsLength(res.data.length);
          return supabase
            .from("metrics")
            .select()
            .in("session_id", [res.data[0]?.id, res.data[1]?.id])
            .order("timestamp", { ascending: true })
            .then((res) =>
              res.data
                .filter((el) => el.heart_rate !== 0)
                .filter((_, i) => i % 5 === 0),
            );
        }),
    [supabase],
  );

  const sessions = useAsyncState<SupabaseCall<typeof supabasePromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(sessions)) {
      sessions.fire(async () => supabasePromise());
    }
  }, [supabase, sessions]);

  // Performance Progress Call
  const progressPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select(
          "total_distance , average_speed , average_power , average_cadence , average_heart_rate , total_time  , date",
        )
        .eq("athlete_id", user.id)
        .order("date", { ascending: false })
        .then((res) => res.data),
    [supabase],
  );

  const progressSessions =
		useAsyncState<SupabaseCall<typeof progressPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(progressSessions)) {
      progressSessions.fire(async () => progressPromise());
    }
  }, [supabase, progressSessions]);

  // Positions Progress Call
  const positionsPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select(
          "session_statistics(*),date,athlete_bikes(bike_types(name)),ride_types(name)",
        )
        .order("date", { ascending: true })
        .eq("athlete_id", user.id)
        .gte("date", rangeLow.toISOString())
        .lte("date", rangeHigh.toISOString())
        .then((res) => res.data) as any,
    [supabase],
  );

  const positionSessions: AsyncState<
		{
			session_statistics: {
				cadence_max: number;
				created_at: string;
				default_position_id: number;
				custom_position_id: number;
				heart_rate_average: number;
				heart_rate_max: number;
				id: number;
				pedal_balance_left: number;
				pedal_balance_right: number;
				position_percentage: number;
				power_average: number;
				power_max: number;
				session_id: string;
				slope_average: number;
				slope_negative_percentage: number;
				slope_positive_percentage: number;
				speed_average: number;
				speed_max: number;
			}[];
			date: string;
			ride_types: { name: string };
			athlete_bikes: {
				bike_types: {
					name: string;
				};
			};
		}[]
	> = useAsyncState(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(positionSessions)) {
      positionSessions.fire(async () => positionsPromise() as any);
    }
  }, [supabase, positionSessions]);

  // Custom Positions Call
  const customPositionsPromise = useCallback(
    () =>
      supabase
        .from("athlete_bikes")
        .select("id")
        .eq("athlete_id", user.id)
        .then((athleteBikesRes) =>
          supabase
            .from("custom_positions")
            .select("*")
            .in(
              "bike_id",
              athleteBikesRes.data.map((row) => row.id),
            )
            .throwOnError()
            .then((res) => res.data),
        ),
    [supabase],
  );

  const customPositionsCall =
		useAsyncState<SupabaseCall<typeof customPositionsPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(customPositionsCall)) {
      customPositionsCall.fire(async () => customPositionsPromise());
    }
  }, [supabase, customPositionsCall]);

  // Calendar Call
  const calendarPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select("date,recording_name,total_time,total_distance,tss")
        .eq("athlete_id", user.id)
        .order("date", { ascending: true })
        .gte("date", startOfWeek)
        .lte("date", endOfWeek)
        .throwOnError()
        .then((res) => res.data),
    [supabase],
  );

  const calendarCall = useAsyncState<SupabaseCall<typeof calendarPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(calendarCall)) {
      calendarCall.fire(async () => calendarPromise());
    }
  }, [supabase, calendarCall]);

  // Trends Call
  const trendsPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select(
          "efficiency_factor,effort_score,normalized_power,workload,intensity_factor,tss,date",
        )
        .eq("athlete_id", user.id)
        .order("date", { ascending: false })
        .gte("date", trendsRangeLow.toISOString())
        .lte("date", rangeHigh.toISOString())
        .throwOnError()
        .then((res) => res.data),
    [supabase],
  );

  const trendsCall = useAsyncState<SupabaseCall<typeof trendsPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(trendsCall)) {
      trendsCall.fire(async () => trendsPromise());
    }
  }, [supabase, trendsCall]);

  //MobileScreen PopUp
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  const handleDownloadApp = () => {
    window.location.href =
      "  https://play.google.com/store/apps/details?id=com.mirror3dlab.darefore&hl=en&gl=US";
  };

  return (
    <>
      {isFulfilled(progressSessions) &&
      isFulfilled(positionSessions) &&
      isFulfilled(sessions) &&
      isFulfilled(trendsCall) &&
      isFulfilled(customPositionsCall) &&
      isFulfilled(calendarCall) ? (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "1.1em",
          }}
        >
          <Box>
            <Snackbar
              sx={{
                marginTop: 22,
                color: "white",
                "& .MuiSnackbarContent-root": {
                  backgroundColor: "#201B20",
                  color: "white",
                },
                width: isMobileScreen ? "0px" : "450px",
              }}
              ContentProps={{
                sx: {
                  flexWrap: "nowrap",
                },
              }}
              open={snackbarOpen}
              message="Start using the app to record your first session and/or connect to your third-party platforms accounts."
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setSnackbarOpen(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </Box>
          <Grid item xs={12} md={3}>
            <DashboardCard
              widgetTitle="MY RIDES"
              component={
                sessionsLength > 0 ? (
                  <WithCache
                    tables={["ride_types"]}
                    component={(cached) => (
                      <MyRidesTabs
                        lastSession={lastSessionData}
                        previousSession={previousSessionData}
                        lastSessionMetrics={sessions.result?.filter(
                          (metric) =>
                            metric?.session_id === lastSessionData?.id,
                        )}
                        previousSessionMetrics={sessions.result?.filter(
                          (metric) =>
                            metric?.session_id === previousSessionData?.id,
                        )}
                        rideTypes={cached.ride_types}
                      />
                    )}
                  />
                ) : (
                  <MyRidesCardEmpty />
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={9} container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} md={7}>
              <DashboardCard
                widgetTitle="PERFORMANCE PROGRESS"
                widgetSubtitle="Average metrics over the Last Six Months"
                viewMore="/dashboard/progress"
                component={
                  <PerformanceProgressWidget
                    sessions={progressSessions.result}
                  />
                }
              />
            </Grid>

            {isSmallerThanMd ? (
              <>
                <Grid item xs={12} md={5}>
                  {isPremium ? (
                    <WithCache
                      tables={["default_positions"]}
                      component={(cached) => (
                        <PositionProgressWidgetTabs
                          sessions={positionSessions.result}
                          defaultPositions={cached.default_positions}
                          customPositions={customPositionsCall.result}
                        />
                      )}
                    />
                  ) : (
                    <LockScreenWidget />
                  )}
                </Grid>
                <Grid item xs={12} md={7}>
                  <DashboardCard
                    widgetTitle="WEEKLY CALENDAR"
                    viewMore="/dashboard/calendar"
                    component={
                      <CalendarWidget
                        date_session={calendarCall.result}
                        endOfWeek={endOfWeek}
                        startOfWeek={startOfWeek}
                      />
                    }
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={5}>
                  <DashboardCard
                    widgetTitle="WEEKLY CALENDAR"
                    viewMore="/dashboard/calendar"
                    component={
                      <CalendarWidget
                        date_session={calendarCall.result}
                        endOfWeek={endOfWeek}
                        startOfWeek={startOfWeek}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  {isPremium ? (
                    <WithCache
                      tables={["default_positions"]}
                      component={(cached) => (
                        <PositionProgressWidgetTabs
                          sessions={positionSessions.result}
                          defaultPositions={cached.default_positions}
                          customPositions={customPositionsCall.result}
                        />
                      )}
                    />
                  ) : (
                    <LockScreenWidget />
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} md={5}>
              {trendsCall.result.length > 0 ? (
                <DashboardCard
                  widgetTitle="TRENDS"
                  viewMore="/dashboard/trends"
                  component={<TrendsWidget sessions={trendsCall.result} />}
                />
              ) : (
                <DashboardCard
                  widgetTitle="TRENDS"
                  viewMore="/dashboard/trends"
                  component={<TrendsWidgetEmpty />}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={2} sx={{ padding: "16px" }}>
            <Grid item xs={12} md={3}>
              <Skeleton
                variant="text"
                animation="wave"
                sx={{ width: "70%", marginBottom: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width="100%"
                animation="wave"
                sx={{
                  borderRadius: "10px",
                  marginBottom: "16px",
                  height: { xs: "590px", xl: "750px" },
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={9}
              rowSpacing={1}
              columnSpacing={2}
            >
              <Grid item xs={12} md={7}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "70%", marginBottom: "8px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  animation="wave"
                  sx={{
                    borderRadius: "10px",
                    height: { xs: "270", xl: "370px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "70%", marginBottom: "8px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  animation="wave"
                  sx={{
                    borderRadius: "10px",
                    height: { xs: "270", xl: "370px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "70%", marginBottom: "8px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  animation="wave"
                  sx={{
                    borderRadius: "10px",
                    height: { xs: "270", xl: "340px" },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ width: "70%", marginBottom: "8px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  animation="wave"
                  sx={{
                    borderRadius: "10px",
                    height: { xs: "270", xl: "340px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {isMobileScreen && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ sx: { background: "#201C20", borderRadius: "8px" } }}
          sx={{
            "& .MuiBackdrop-root": {
              backdropFilter: "blur(10px)",
            },
          }}
          keepMounted
        >
          <DialogContent>
            <DialogContentText
              sx={{
                textAlign: "center",
              }}
              variant="h3"
            >
              Darefore Hub is optimized for wider screens.
            </DialogContentText>
            <DialogContentText
              sx={{
                textAlign: "center",
              }}
              variant="h3"
            >
              For smaller screens, use the app.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              "> :not(:first-of-type)": {
                marginLeft: "0px",
              },
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleDownloadApp}
              sx={{ width: "210px" }}
            >
              <Typography variant="h5">Download app</Typography>
            </Button>
            <Button onClick={() => setOpen(false)} sx={{ width: "210px" }}>
              <Typography variant="h5">Continue with hub</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
