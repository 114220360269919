import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import close from "../../../assets/img/views/settings/Close.svg";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useUser } from "src/components/Authenticated";
import { useSnackbar } from "notistack";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import type { TableRow } from "src/contexts/CacheContext";
import infoicon from "../../../assets/img/views/profile-setup/info-icon.svg";
import convert from "convert-units";

export type BikeInfoProps = {
  bike_name: string;
  bike_weight: number;
  bike_weight_pounds: number;
  bike_type: number;
  units: string;
};

export type BikeInfoViewProps = {
  athleteBikes: Array<TableRow<"athlete_bikes">>;
  bikeTypes: Array<TableRow<"bike_types">>;
};

export default function BikeInfoView(props: BikeInfoViewProps) {
  const user = useUser();
  const supabase = useSupabase();
  const units = useUnits();
  const [deleteItemId, setDeleteItemId] = useState<number>();

  const currentBikes = useMemo(
    () => props.athleteBikes.filter((bike) => !bike.soft_deleted),
    [props.athleteBikes],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        bike_name: yup.string().required("Bike Name is required"),
        bike_weight: yup
          .number()
          .test(
            "minimum bike weight",
            "Bike Weight cannot be lower than 3kg",
            (value, { parent }) =>
              !value || parent.units === "imperial" || parent.bike_weight >= 3,
          )
          .test(
            "maximum bike weight",
            "Weight cannot be greater than 20kg",
            (value, { parent }) =>
              !value || parent.units === "imperial" || parent.bike_weight <= 20,
          )
          .test(
            "metric weight is required",
            "Field is required",
            (value, { parent }) => !!value || parent.units === "imperial",
          )
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .nullable(),
        bike_weight_pounds: yup
          .number()
          .test(
            "minimum imperial weight",
            "Weight cannot be lower than 6lbs",
            (value, { parent }) =>
              !value ||
              parent.units === "metric" ||
              parent.bike_weight_pounds >= 6,
          )
          .test(
            "maximum imperial weight",
            "Weight cannot be greater than 44lbs",
            (value, { parent }) =>
              !value ||
              parent.units === "metric" ||
              parent.bike_weight_pounds <= 44,
          )
          .test(
            "imperial weight is required",
            "Field is required",
            (value, { parent }) => {
              return !!value || parent.units === "metric";
            },
          )
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .nullable(),
        bike_type: yup
          .number()
          .nullable()
          .required("Bike Type is required")
          .oneOf(
            props.bikeTypes.map((type) => type.id),
            "Please select a valid Bike Type",
          ),
      }),
    [],
  );

  const formik = useFormik<BikeInfoProps>({
    initialValues: {
      bike_name: "",
      bike_weight: undefined,
      bike_weight_pounds: undefined,
      bike_type: undefined,
      units,
    },
    validationSchema,
    onSubmit: (values) => {
      if ("fire" in saveAtheleteBikesCall) {
        saveAtheleteBikesCall.fire(async () => saveAtheletBikesPromise(values));
      }
    },
  });

  const saveAtheletBikesPromise = useCallback(
    (values: BikeInfoProps) => {
      const bikeWeight =
        values.units === "imperial" && values.bike_weight_pounds !== null
          ? Number(
              convert(values.bike_weight_pounds).from("lb").to("kg").toFixed(1),
            )
          : Number(values.bike_weight);

      return supabase
        .from("athlete_bikes")
        .insert({
          bike_name: values.bike_name,
          bike_weight: bikeWeight,
          bike_type: values.bike_type,
          athlete_id: user.id,
        })
        .select()
        .throwOnError();
    },
    [supabase],
  );

  const saveAtheleteBikesCall =
    useAsyncState<SupabaseCall<typeof saveAtheletBikesPromise>>();

  //Delete
  const deletePromise = useCallback(
    (bikeId: number) =>
      supabase
        .from("athlete_bikes")
        .update({ soft_deleted: true })
        .eq("id", bikeId)
        .throwOnError(),
    [supabase],
  );

  const deleteCall = useAsyncState<SupabaseCall<typeof deletePromise>>();

  //SnackBar
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFulfilled(saveAtheleteBikesCall)) {
      enqueueSnackbar("New bike added!", {
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [saveAtheleteBikesCall]);

  const theme = useTheme();
  const breakpoint = theme.breakpoints.down("md");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "98%",
            background: "#201B20",
            borderRadius: "10px",
            paddingY: "1.5em",
            paddingX: isMobileScreen ? "1rem" : "2rem",
            minHeight: "70vh",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: ".8125rem", marginBottom: "1.5rem" }}>
              BIKE INFORMATION
            </Typography>
            <Tooltip
              title="You need to have at least one Bike entity."
              placement="right"
              arrow
            >
              <img src={infoicon} alt="info_icon" width="10" height="11" />
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              [breakpoint]: {
                flexDirection: "column",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                width: "30%",
                marginLeft: isMobileScreen ? "20px" : "60px",
                [breakpoint]: {
                  alignItems: isMobileScreen ? "flex-start" : "center",
                  width: "80%",
                },
              }}
            >
              <FormControl>
                <TextField
                  name="bike_name"
                  label={"Bike Name"}
                  type="string"
                  onBlur={formik.handleBlur}
                  value={formik.values.bike_name}
                  error={Boolean(
                    formik.touched.bike_name && formik.errors.bike_name,
                  )}
                  helperText={
                    formik.touched.bike_name && formik.errors.bike_name
                  }
                  onChange={formik.handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel id="bike_type">Bike Type</InputLabel>
                <Select
                  id="bike_type"
                  name="bike_type"
                  label="Bike Type"
                  value={formik.values.bike_type ?? ""}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.bike_type && formik.errors.bike_type,
                  )}
                >
                  {props.bikeTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.bike_type && formik.errors.bike_type ? (
                  <FormHelperText sx={{ color: "#FF1943" }}>
                    {formik.touched.bike_type && formik.errors.bike_type}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <FormControl>
                {units === "metric" ? (
                  <TextField
                    name="bike_weight"
                    label={"Bike Weight (kg)"}
                    type="string"
                    onBlur={formik.handleBlur}
                    value={formik.values.bike_weight ?? ""}
                    error={Boolean(
                      formik.touched.bike_weight && formik.errors.bike_weight,
                    )}
                    helperText={
                      formik.touched.bike_weight && formik.errors.bike_weight
                    }
                    onChange={formik.handleChange}
                  />
                ) : (
                  <TextField
                    name="bike_weight_pounds"
                    label={"Bike Weight (lbs)"}
                    type="string"
                    onBlur={formik.handleBlur}
                    value={formik.values.bike_weight_pounds ?? ""}
                    error={Boolean(
                      formik.touched.bike_weight_pounds &&
                        formik.errors.bike_weight_pounds,
                    )}
                    helperText={
                      formik.touched.bike_weight_pounds &&
                      formik.errors.bike_weight_pounds
                    }
                    onChange={formik.handleChange}
                  />
                )}
              </FormControl>
              <Button size="small" type="submit" sx={{ width: "10rem" }}>
                Add a new bike
              </Button>
            </Box>

            <Box
              width={"70%"}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginLeft: isMobileScreen ? "20px" : "60px",
                [breakpoint]: {
                  width: "95%",
                },
              }}
            >
              {currentBikes.length === 0 ? (
                <Box width={"60%"}>
                  <Typography fontSize={"1rem"}>
                    Your added bikes will appear here!
                  </Typography>
                </Box>
              ) : (
                <Box
                  width={"85%"}
                  sx={{
                    [breakpoint]: {
                      width: "100%",
                      margin: "2rem auto 0",
                    },
                  }}
                >
                  {currentBikes.map((datum, index) => (
                    <Box
                      key={datum.id}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          border: "1px solid #FFF",
                          borderRadius: "6px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1.125rem",
                          marginBottom: ".9375rem",
                          width: "100%",
                        }}
                      >
                        <Grid container>
                          <Grid item sm={5} xs={12}>
                            <Typography variant="h4">
                              {datum.bike_name}
                            </Typography>
                          </Grid>
                          <Grid item sm={5} xs={12}>
                            <Typography variant="h4">
                              {datum.bike_type === 1 ? "Road" : "TT"}
                            </Typography>
                          </Grid>
                          <Grid item sm={2} xs={12}>
                            <Typography variant="h4">
                              {units === "metric"
                                ? `${datum.bike_weight} kg`
                                : `${(datum.bike_weight * 2.20462).toFixed(
                                    0,
                                  )} lbs`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box marginLeft={"1rem"}>
                        <IconButton
                          onClick={() => {
                            setDeleteItemId(datum.id);
                            setShowConfirmation(true);
                          }}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: ".9375rem",
                          }}
                        >
                          <img src={close} alt="close" />
                        </IconButton>
                      </Box>
                      <Dialog
                        open={showConfirmation}
                        onClose={() => setShowConfirmation(false)}
                        sx={{
                          "& .MuiPaper-root": {
                            background: "#232a2e",
                          },
                        }}
                      >
                        {currentBikes.length === 1 ? (
                          <>
                            <DialogContent>
                              You need to have at least one bike
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setShowConfirmation(false)}
                                size="small"
                              >
                                Close
                              </Button>
                            </DialogActions>
                          </>
                        ) : (
                          <>
                            <DialogTitle>Delete Confirmation</DialogTitle>
                            <DialogContent>
                              Are you sure you want to delete this item?
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setShowConfirmation(false)}
                                size="small"
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => {
                                  if ("fire" in deleteCall) {
                                    deleteCall.fire(async () =>
                                      deletePromise(deleteItemId).then((r) => {
                                        setShowConfirmation(false);
                                        return r;
                                      }),
                                    );
                                  }
                                }}
                                size="small"
                                variant="contained"
                                autoFocus
                              >
                                Delete
                              </Button>
                            </DialogActions>
                          </>
                        )}
                      </Dialog>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}
