import AppleIcon from "@mui/icons-material/Apple";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import SearchIcon from "@mui/icons-material/Search";
import * as yup from "yup";
import Lottie from "lottie-react";
import logojson from "../../assets/logoGif.json";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isRejected } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import type { TableRow } from "src/contexts/CacheContext";

const autocompletePopper = (props) => (
  <Popper
    {...props}
    sx={{
      height: "50px",
    }}
    position="bottom-start"
  />
);

export type RegisterViewProps = {
  countries: Array<TableRow<"country">>;
};

export default function RegisterView(props: RegisterViewProps) {
  const supabase = useSupabase();
  const navigate = useNavigate();
  const signUpCall = useAsyncState<SupabaseCall<typeof supabase.auth.signUp>>();
  const formik = useFormik<{
    name: string;
    password: string;
    confirmPassword: string;
    email: string;
    confirmEmail: string;
    countryCode: string;
    terms: boolean;
  }>({
    initialValues: {
      name: "",
      password: "",
      confirmPassword: "",
      email: "",
      confirmEmail: "",
      countryCode: "",
      terms: false,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("The email provided should be a valid email address")
        .max(255)
        .required("Email is required"),
      confirmEmail: yup.string().oneOf([yup.ref("email")], "Emails must match"),
      password: yup.string().min(8).max(255).required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match"),
      countryCode: yup
        .string()
        .oneOf(
          props.countries.map((country) => country.code),
          "Invalid country",
        )
        .required("This field is required"),
      terms: yup
        .boolean()
        .oneOf([true], "Please accept our Terms & Conditions"),
    }),
    onSubmit: (values) => {
      if ("fire" in signUpCall) {
        signUpCall.fire(() =>
          supabase.auth.signUp({
            email: values.email,
            password: values.password,
            options: {
              data: {
                name: values.name,
                country: values.countryCode,
                confirmation_sent_at: Date.now(),
              },
            },
          }),
        );
      }
    },
  });

  useEffect(() => {
    if (
      isRejected(signUpCall) &&
      signUpCall.error?.message === "User already registered"
    ) {
      formik.setFieldError("email", "Email is taken");
    }
    if (
      isFulfilled(signUpCall) &&
      signUpCall.result?.data?.user?.aud === "authenticated"
    ) {    
      window.location.href = '/'
    }
  }, [signUpCall]);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: { lg: "0 0 50%", md: "0 0 100%", xs: "0 0 100%" },
          "@media (max-width: 330px)": {
            alignItems: "flex-start",
          },
          pt: "120px",
        }}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1em",
            }}
          >
            <Box width={"20.375rem"}>
              <Typography variant="h1" color="text.secondary">
                Sign Up
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
                mt: "1.125rem",
              }}
            >
              <FormControl>
                <TextField
                  name="name"
                  label="Full Name"
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "20.5rem",
                    },
                  }}
                />
                {Boolean(formik.touched.name) && formik.errors.name && (
                  <FormHelperText error>{formik.errors.name}</FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "20.5rem",
                    },
                  }}
                />
                {Boolean(formik.touched.password) && formik.errors.password && (
                  <FormHelperText error>
                    {formik.errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <TextField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "20.5rem",
                    },
                  }}
                />
                {Boolean(formik.touched.confirmPassword) &&
                  formik.errors.confirmPassword && (
                    <FormHelperText error>
                      {formik.errors.confirmPassword}
                    </FormHelperText>
                  )}
              </FormControl>
              <FormControl>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "20.5rem",
                    },
                  }}
                />
                {Boolean(formik.touched.email) && formik.errors.email && (
                  <FormHelperText error>{formik.errors.email}</FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <TextField
                  label="Confirm Email"
                  name="confirmEmail"
                  type="email"
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmEmail}
                  onChange={formik.handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      width: "20.5rem",
                    },
                  }}
                />
                {Boolean(formik.touched.confirmEmail) &&
                  formik.errors.confirmEmail && (
                    <FormHelperText error>
                      {formik.errors.confirmEmail}
                    </FormHelperText>
                  )}
              </FormControl>
              <Autocomplete
                id="countryCode"
                options={props.countries.map((country) => ({
                  value: country.code,
                  label: country.name,
                }))}
                popupIcon={<SearchIcon sx={{ transform: "none" }} />}
                PopperComponent={autocompletePopper}
                isOptionEqualToValue={(value, option) =>
                  value.value === option.value
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "countryCode",
                    props.countries.find(
                      (country) => country.name === e.target["innerText"],
                    )?.code ?? "",
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      formik.touched.countryCode && formik.errors.countryCode,
                    )}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    label="Country"
                    helperText={
                      Boolean(formik.touched.countryCode) &&
                      formik.errors.countryCode
                    }
                    value={
                      props.countries.find(
                        (c) => c.code === formik.values.countryCode,
                      )?.name ?? "Albania"
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        width: "20.5rem",
                      },
                    }}
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                width: "20.375rem",
                mt: "1rem",
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="terms"
                      onChange={formik.handleChange}
                      checked={formik.values.terms}
                    />
                  }
                  label={
                    <>
                      <Typography sx={{ display: "inline" }} variant="h4">
                        I accept the
                      </Typography>{" "}
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          display: "inline",
                        }}
                        variant="h4"
                        onClick={() =>
                          window.open("https://darefore.com/terms-of-use/")
                        }
                      >
                        Terms & Conditions
                      </Typography>
                    </>
                  }
                />
                {Boolean(formik.touched.terms) && formik.errors.terms && (
                  <FormHelperText error>{formik.errors.terms}</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                mt: "1rem",
              }}
            >
              <Button
                variant="contained"
                size="small"
                className="btn-width"
                type="submit"
              >
                Sign Up Now
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className="btn-width"
                startIcon={<GoogleIcon />}
                onClick={(_) =>
                  supabase.auth.signInWithOAuth({ provider: "google" })
                }
              >
                <Typography variant="h5">Sign Up With Google</Typography>
              </Button>
              {/* <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-width"
                  startIcon={<FacebookOutlinedIcon />}
                  onClick={(_) =>
                    supabase.auth.signInWithOAuth({ provider: "facebook" })
                  }
                >
                  <Typography variant="h5">Sign Up With Facebook</Typography>
                </Button> */}
              {/* <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-width"
                  startIcon={<AppleIcon />}
                  onClick={(_) =>
                    supabase.auth.signInWithOAuth({ provider: "apple" })
                  }
                >
                  <Typography variant="h5">Sign Up With Apple</Typography>
                </Button> */}
              <Box>
                <Typography sx={{ display: "inline-block" }} variant="body2">
                  Already have an account?
                </Typography>{" "}
                <Typography
                  sx={{ display: "inline-block", color: "#BC6D29" }}
                  variant="body1"
                  component={Link}
                  to="/account/login"
                >
                  Sign in here
                </Typography>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "none", lg: "flex" },
          flex: "0 0 50%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#BC6D29",
          borderRadius: "2.5rem 0rem 0rem 2.5rem",
        }}
      >
        <Lottie
          animationData={logojson}
          loop={true}
          style={{ width: "600px" }}
        />
      </Box>
    </Box>
  );
}
