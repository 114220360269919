import { FormElements } from "./FormElements";
import SidebarBtnElement from "./SidebarBtnElement";
import { Grid, useMediaQuery } from "@mui/material";

export default function DesignerSidebar() {
  const isMobileScreen = useMediaQuery("(max-width:550px)");

  return (
    <>
      {!isMobileScreen ? (
        <Grid container spacing={{ md: 4, xs: 2 }}>
          <Grid item xs={6} md={3.5} xl={4}>
            <SidebarBtnElement formElement={FormElements.Warmup} />
          </Grid>
          <Grid item xs={3.5} xl={4}>
      <SidebarBtnElement formElement={FormElements.Intervals} />
    </Grid>
          <Grid item xs={6} md={3.5} xl={4}>
            <SidebarBtnElement formElement={FormElements.Cooldown} />
          </Grid>
          <Grid item xs={6} md={3.5} xl={4}>
            <SidebarBtnElement formElement={FormElements.Recovery} />
          </Grid>
          <Grid item xs={6} md={3.5} xl={4}>
            <SidebarBtnElement formElement={FormElements.Active} />
          </Grid>
          <Grid item xs={6} md={3.5} xl={4}>
            <SidebarBtnElement formElement={FormElements.Other} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SidebarBtnElement formElement={FormElements.Warmup} />
          </Grid>
          <Grid item xs={4}>
            <SidebarBtnElement formElement={FormElements.Cooldown} />
          </Grid>
          <Grid item xs={4}>
            <SidebarBtnElement formElement={FormElements.Recovery} />
          </Grid>
          <Grid item xs={4}>
            <SidebarBtnElement formElement={FormElements.Active} />
          </Grid>
          <Grid item xs={4}>
            <SidebarBtnElement formElement={FormElements.Other} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
