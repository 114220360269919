import { Box, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

export type DashboardCardProps = {
  widgetTitle: string;
  widgetSubtitle?: string;
  viewMore?: string;
  height?: string;
  width?: string;
  component?: ReactNode;
};

export default function DashboardCard(props: DashboardCardProps) {
  return (
    <Box
      sx={{
        backgroundColor: "#201B20",
        width: props.width,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        paddingLeft: "1em",
        borderRadius: "10px",
      }}
    >
      <Box>
        <Typography variant="h5" marginBottom="2px">
          {props.widgetTitle}
        </Typography>
        <Typography variant="subtitle1" sx={{ opacity: 0.5 }}>
          {props.widgetSubtitle}
        </Typography>
      </Box>

      <Box alignItems={"center"} height={'100%'}>{props.component}</Box>

      {!!props.viewMore && (
        <Box alignSelf={"flex-end"} paddingRight={"13px"}>
          <Typography color={"white"} component={Link} to={props.viewMore}>
            View More
          </Typography>
        </Box>
      )}
    </Box>
  );
}
