import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Skeleton,
	Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncState, { isFulfilled } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import { useSnackbar } from "notistack";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";

export type CoachProps = {
	coach: {
		country_code: string;
		email: string;
		expertise: string[];
		gender: boolean;
		name: string;
		other_info: string | null;
		phone: string | null;
		qualifications: string;
		units: boolean;
		user_id: string;
		image: string | null;
	};
	countries: {
		code: string;
		id: number;
		name: string;
		phone: number[] | null;
	}[];
};

export default function MyCoach(props: CoachProps) {
	const supabase = useSupabase();
	const user = useUser();
	const { enqueueSnackbar } = useSnackbar();
	const [deleteDialog, setDeleteDialog] = useState(false);

	const deletePromise = useCallback(
		() => supabase.from("coach_athletes").delete().eq("athlete_id", user.id),
		[supabase],
	);

	const deleteCall = useAsyncState<SupabaseCall<typeof deletePromise>>();

	const sendDisconnectionNotificationPromise = useCallback(
		async (coachId: string) => {
			return supabase.from("notifications").insert({
				type: "Disconnected",
				content: {
					description: `Athlete ${user.user_metadata.name} (${user.email}) has disconnected.`,
					athlete_id: user.id,
				},
				user_id: coachId,
			});
		},
		[supabase, user],
	);

	const sendDisconnectionNotificationCall =
		useAsyncState<SupabaseCall<typeof sendDisconnectionNotificationPromise>>();

	useEffect(() => {
		if (isFulfilled(sendDisconnectionNotificationCall)) {
			if (sendDisconnectionNotificationCall.result) {
				enqueueSnackbar("Disconnected!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "success",
					autoHideDuration: 2000,
				});
			} else {
				enqueueSnackbar("Disconnection failed!", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "top",
					},
					variant: "error",
					autoHideDuration: 2000,
				});
			}
		}
	}, [sendDisconnectionNotificationCall]);

	const avatar = useMemo(() => {
		if (props.coach.image) {
			return supabase.storage.from("avatars").getPublicUrl(props.coach.image, {
				transform: {
					width: 150,
					height: 150,
				},
			}).data.publicUrl;
		}
		return createAvatar(initials, {
			size: 150,
			radius: 55,
			seed: props.coach.name,
		}).toDataUriSync();
	}, [props.coach]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					backgroundColor: "#2C262D",
					borderRadius: "4px",
					border: "1px solid #BC6D29",
					padding: "1.5em",
					gap: 2,
					alignItems: "center",
					justifyContent: "space-between",
					alignSelf: "flex-start",
				}}
			>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						alignItems: "center",
					}}
				>
					<img
						src={avatar}
						alt="avatar"
						style={{
							borderRadius: "50%",
							width: "80px",
							height: "80px",
							objectFit: "cover",
						}}
					/>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography fontSize={".875rem"}>{props.coach.name}</Typography>
						<Typography fontSize={".75rem"}>Coach</Typography>
						<Typography fontSize={".625rem"} sx={{ opacity: 0.5 }}>
							{
								props.countries.find(
									(country) => country.code === props.coach.country_code,
								).name
							}
						</Typography>
					</Box>
				</Box>
				<Button
					onClick={() => {
						setDeleteDialog(true);
					}}
					variant="contained"
					size="small"
				>
					Disconnect
				</Button>
			</Box>

			<Dialog
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				sx={{
					"& .MuiPaper-root": {
						background: "#232a2e",
					},
				}}
			>
				<>
					<DialogTitle>Delete Confirmation</DialogTitle>
					<DialogContent>
						Are you sure you want to delete your Coach?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDeleteDialog(false)} size="small">
							Cancel
						</Button>
						<Button
							onClick={() => {
								if ("fire" in deleteCall) {
									deleteCall.fire(async () =>
										deletePromise().then((r) => {
											setDeleteDialog(false);
											return r;
										}),
									);
								}
								if ("fire" in sendDisconnectionNotificationCall) {
									sendDisconnectionNotificationCall.fire(async () =>
										sendDisconnectionNotificationPromise(props.coach.user_id),
									);
								}
							}}
							size="small"
							variant="contained"
							autoFocus
						>
							Delete
						</Button>
					</DialogActions>
				</>
			</Dialog>
		</>
	);
}
