import { useMemo, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import MyRidesCard from "./MyRidesCard";
import type { Database, Tables } from "src/utils/DatabaseDefinitions";
import type { TableRow } from "src/contexts/CacheContext";

type TabValue = "previous" | "last" | "next";

export type MyRidesTabsProps = {
  previousSession: Database["public"]["Tables"]["session"]["Row"] & {
    ride_types: Tables<'ride_types'>
    session_statistics: (Tables<'session_statistics'> & {
      custom_positions: Tables<'custom_positions'>, 
      default_positions: Tables<'default_positions'>
    })[]
  };
  lastSession: Database["public"]["Tables"]["session"]["Row"] & {
    ride_types: Tables<'ride_types'>
    session_statistics: (Tables<'session_statistics'> & {
      custom_positions: Tables<'custom_positions'>, 
      default_positions: Tables<'default_positions'>
    })[]
  };
  previousSessionMetrics: Database["public"]["Tables"]["metrics"]["Row"][];
  lastSessionMetrics: Database["public"]["Tables"]["metrics"]["Row"][];
  rideTypes: Array<TableRow<"ride_types">>;
};

export default function MyRidesTabs(props: MyRidesTabsProps) {
  const [value, setValue] = useState<TabValue>("last");

  const handleChange = (_, newValue: TabValue) => {
    setValue(newValue);
  };

  const previousRideType = useMemo(
    () =>
      props.rideTypes.find(
        (item) => item.id === props.previousSession?.type_of_ride_id,
      )?.name,
    [props.previousSession, props.rideTypes],
  );

  const lastRideType = useMemo(
    () =>
      props.rideTypes.find(
        (item) => item.id === props.lastSession?.type_of_ride_id,
      )?.name,
    [props.lastSession, props.rideTypes],
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#201B20",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          paddingRight: "1em",
          borderRadius: "10px",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Tab list"
              sx={{
                "& .MuiTab-root:not(.Mui-selected)": {
                  opacity: 0.5,
                },
                minHeight: "1.5rem",
              }}
            >
              <Tab
                label="Previous"
                value="previous"
                sx={{ minHeight: 0, padding: "1px" }}
              />
              <Tab
                label="Last"
                value="last"
                sx={{ minHeight: 0, padding: "5x" }}
              />
              {/* <Tab
                label="Next"
                value="next"
                disabled
                sx={{ minHeight: 0, padding: "5px" }}
              /> */}
            </TabList>
          </Box>
          <MyRidesCard
              metrics={value === 'previous' ? props.previousSessionMetrics : props.lastSessionMetrics}
              sessions={value === 'previous' ? props.previousSession : props.lastSession}
              ride_type={value === 'previous' ? previousRideType : lastRideType}
            />
        </TabContext>
      </Box>
    </>
  );
}
