import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import cyclistSession from "../../../../assets/img/views/calendar/cyclistIconSession.svg";
import sessionLine from "../../../../assets/img/views/calendar/sessionline.svg";
import workoutIcon from "../../../../assets/img/views/calendar/workoutIcon.svg";
import coachWorkoutIcon from "../../../../assets/img/views/calendar/coachWorkoutIcon.svg";
import workoutLine from "../../../../assets/img/views/calendar/workoutLine.svg";
import plusIcon from "../../../../assets/img/views/calendar/plusIcon.svg";

import {
  Grid,
  Box,
  Divider,
  useMediaQuery,
  styled,
  useTheme,
  Typography,
  Button,
  TextareaAutosize,
} from "@mui/material";
import type { View } from "src/models/calendar";
import PreloadComponent from "src/utils/PreloadComponent";
import { Database, Tables } from "src/utils/DatabaseDefinitions";
import moment from "moment";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import dayjs from "dayjs";
import Actions from "./CoachCalendarActions";
import { WithCache } from "src/contexts/CacheContext";
import { useNavigate } from "react-router-dom";
import CoachSessionViewPreload from "../AthletesSessions/CoachSessionViewPreload";
import { useUser } from "src/components/Authenticated";
import { EventInput } from "@fullcalendar/core";
import zIndex from "@mui/material/styles/zIndex";
import AssignWorkoutModal from "./AssignWorkoutModal";

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    padding-top: 1px;
    padding-bottom : 9px;
    padding-left : ${theme.spacing(3)};

    & .fc-license-message {
      display: none;
    }
    .fc {

      --fc-page-bg-color: none;

      .fc-col-header-cell {
        padding: ${theme.spacing(1)};
        background: none;
        border: none !important;
      }

      .fc-scrollgrid {
        border:none !important;
      }

      .fc-scroller {
        overflow-y: hidden !important;
     }

      .fc-scrollgrid-section-header {
        border: none;
      }

      &.fc-theme-standard td, 
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border : none !important
      }

      .fc-col-header {
        background : #201B20;
      }

      .fc-daygrid-day.fc-day-other {
        opacity: 0.5;
      }

      .fc-daygrid-day-frame {
        border: 1px solid #64605D;
        height: 117px;
      }

      .fc-daygrid-day-top {
        display : flex;
        flex-direction : row !important;
      }

      .fc-daygrid-day-number {
        width: 100%;
        padding:1.5px !important;
        padding-left : 5px !important
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-event {
        cursor : pointer;
      }

      .fc-event-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #E3DFDC;
        font-family: 'DejaVu Sans';
        font-size: 10px;
        margin-bottom:2px;
      }

      .fc-popover,
      .fc-more-popover {
        background-color : #201B20 !important;
        box-shadow : none !important;
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: rgba(188, 109, 41, 0.2);
      }
      
      .fc-daygrid-dot-event:hover, 
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: ${theme.colors.alpha.black[5]} !important;
      }

      .fc-cell-shaded, 
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[10]} !important;
        color: ${theme.colors.alpha.black[70]} !important;
      }

      //Styles for MultiMonthView

      .fc-multimonth {
        border: none !important;
      }

      .fc-multimonth-daygrid {
        background-color: #201B20 
      }

      .fc-multimonth-daygrid-table .fc-daygrid-day-frame {
        height: 85px !important;
      }

      .fc .fc-cell-shaded, .fc .fc-day-disabled {
        background :none !important
      }


      .fc-multimonth-header-table .fc-col-header-cell {
        padding: 4px !important;
        padding-bottom: 25px !important;

      }
    }
`,
);

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export type Dates = {
  date_session: Pick<
    Database["public"]["Tables"]["session"]["Row"],
    "date" | "recording_name" | "id" | "total_time" | "total_distance" | "tss"
  >[];
  assignedWorkout: Tables<"assigned_workout">[];
  athleteId: string
};

export type PreloadProps = {
  userId: string;
};

export default function CoachCalendar(props: PreloadProps) {
  const user = useUser();
  return (
    <>
      <PreloadComponent<{
        date_sessions: Pick<
          Database["public"]["Tables"]["session"]["Row"],
          | "date"
          | "recording_name"
          | "id"
          | "total_time"
          | "total_distance"
          | "tss"
        >[];
        assignedWorkouts: Tables<"assigned_workout">[];
      }>
        promises={{
          date_sessions: async (supabase) =>
            supabase
              .from("session")
              .select("date,recording_name,id,total_time,total_distance,tss")
              .order("date", { ascending: true })
              .eq("athlete_id", props.userId)
              .then((res) => res.data),
          assignedWorkouts: async (supabase) =>
            supabase
              .from("assigned_workout")
              .select("*,assigned_training_plan(*)")
              .order("date", { ascending: true })
              .eq("user_id", props.userId)
              .eq("assigned_training_plan.assigned_by", user.id)
              .then((res) => res.data),
        }}
        component={(promise) => (
          <>
            <ApplicationsCalendar
              assignedWorkout={promise.assignedWorkouts}
              date_session={promise.date_sessions}
              athleteId={props.userId}
            />
          </>
        )}
      />
    </>
  );
}

function ApplicationsCalendar(props: Dates) {
  const theme = useTheme();

  const units = useUnits();
  const calendarRef = useRef<FullCalendar | null>(null);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const navigation = useNavigate();
  const [insights, setInsights] = useState<boolean>(false);
  const [selectedDayToAssignWorkout, setSelectedDayToAssignWorkout] = useState<Date>()
  const [assignedWorkouts, setAssignedWorkouts] = useState(props.assignedWorkout)
  const [id, setId] = useState<string>("");

  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<View>("dayGridWeek");

  //Action Bar
  const handleDateToday = (): void => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();
      calApi.today();
      setDate(calApi.getDate());
    }
  };

  const handleDatePrev = (): void => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();
      calApi.prev();
      setDate(calApi.getDate());
    }
  };

  const handleDateNext = (): void => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();
      calApi.next();
      setDate(calApi.getDate());
    }
  };

  const changeView = (changedView: View): void => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();
      calApi.changeView(changedView);
      setView(changedView);
    }
  };

  //MobileView
  useEffect(() => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();
      const changedView = mobile ? "multiMonthYear" : "dayGridMonth";
      calApi.changeView(changedView);
      setView(changedView);
    }
  }, [mobile]);

  const rangeLow = useMemo(() => {
    return dayjs(date).date(1);
  }, [date]);

  const rangeHigh = useMemo(() => {
    return dayjs(date).endOf("month");
  }, [date]);

  // finding how many days is the first week
  const firstWeek = useMemo(() => {
    const indexOfDay = rangeLow.isoWeekday();
    const days: Array<Day> = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return [...days.slice(indexOfDay - 1)];
  }, [rangeLow]);

  // Sessions for the first week
  const firstWeekSessions = useMemo(() => {
    return props.date_session.filter(
      (session) =>
        rangeLow
          .subtract(7 - firstWeek.length, "day")
          .isSameOrBefore(new Date(session.date), "day") &&
        rangeLow
          .add(firstWeek.length - 1, "day")
          .isSameOrAfter(new Date(session.date), "day"),
    );
  }, [rangeLow]);

  // new range low removed the first week.
  const newRangeLow = useMemo(() => {
    return rangeLow.add(firstWeek.length, "day");
  }, [rangeLow]);

  // finding how many days is the last week
  const lastWeek = useMemo(() => {
    const indexOfDay = rangeHigh.isoWeekday();
    const days: Array<Day> = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return [...days.slice(0, indexOfDay)];
  }, [rangeHigh]);

  // last week sessions
  const lastWeekSessions = useMemo(() => {
    return props.date_session.filter(
      (session) =>
        rangeHigh
          .subtract(lastWeek.length - 1, "day")
          .isSameOrBefore(new Date(session.date), "day") &&
        rangeHigh
          .add(7 - lastWeek.length, "day")
          .isSameOrAfter(new Date(session.date), "day"),
    );
  }, [rangeLow]);

  // new range high removed last week
  const newRangeHigh = useMemo(() => {
    return rangeHigh.subtract(lastWeek.length, "day");
  }, [rangeHigh]);

  const filterSessions = useMemo(() => {
    return props.date_session.filter(
      (session) =>
        newRangeLow.isSameOrBefore(new Date(session.date), "month") &&
        newRangeHigh.isSameOrAfter(new Date(session.date), "month"),
    );
  }, [rangeLow, rangeHigh]);

  const resultObject: {
    total_distance: number;
    total_time: number;
    tss: number;
  }[] = useMemo(() => {
    const distanceSum = {};
    const timeSum = {};
    const tssSum = {};
    const result = [];
    for (let i = 1; i <= newRangeHigh.diff(newRangeLow, "week") + 1; i++) {
      distanceSum[i] = 0;
      timeSum[i] = 0;
      tssSum[i] = 0;
      // Filtering Sessions for Each Week
      const weekSession = filterSessions.filter(
        (session) =>
          newRangeLow
            .add((i - 1) * 7, "day")
            .isSameOrBefore(new Date(session.date), "day") &&
          newRangeLow
            .add(i, "week")
            .subtract(1, "day")
            .isSameOrAfter(new Date(session.date), "day"),
      );

      if (weekSession.length > 0) {
        for (const session of weekSession) {
          distanceSum[i] += session.total_distance;
          timeSum[i] += session.total_time;
          tssSum[i] += session.tss;
        }
      }
    }
    distanceSum[0] = 0;
    timeSum[0] = 0;
    tssSum[0] = 0;
    if (firstWeekSessions.length > 0) {
      for (const session of firstWeekSessions) {
        distanceSum[0] += session.total_distance;
        timeSum[0] += session.total_time;
        tssSum[0] += session.tss;
      }
    }
    const lastWeekLength = Object.keys(distanceSum).length;
    distanceSum[lastWeekLength] = 0;
    timeSum[lastWeekLength] = 0;
    tssSum[lastWeekLength] = 0;
    if (lastWeekSessions.length > 0) {
      for (const session of lastWeekSessions) {
        distanceSum[lastWeekLength] += session.total_distance;
        timeSum[lastWeekLength] += session.total_time;
        tssSum[lastWeekLength] += session.tss;
      }
    }

    for (const key in distanceSum) {
      result[key] = {
        total_distance: distanceSum[key],
        total_time: timeSum[key],
        tss: tssSum[key],
      };
    }
    return result;
  }, [filterSessions, firstWeekSessions, lastWeekSessions]);

  // Event Fetching
  const eventDates = useMemo(() => {
    const fetchedEvents = props.date_session.map((data) => {
      const event: EventInput = {
        id: data.id,
        date: new Date(data.date),
        allDay: true,
        extendedProps: {
          type: "session",
          distance: data.total_distance?.toFixed(1) ?? 0,
          time: data?.total_time ?? 0,
          tss: data?.tss?.toFixed(2) ?? 0,
        },
      };

      if (view !== "multiMonthYear") {
        event.title = data.recording_name?.toString();
      }

      return event;
    });

    assignedWorkouts.forEach((data) => {
      const event: EventInput = {
        id: `workout-${data.id}`,
        date: new Date(data.date),
        allDay: true,
        extendedProps: {
          type: data.assigned_training_plan_id || data.coach_id
            ? "coach workout"
            : "athlete workout",
          distance: data.distance?.toFixed(1) ?? 0,
          time: data?.duration ?? 0,
          tss: data?.tss?.toFixed(2) ?? 0,
        },
      };

      if (view !== "multiMonthYear") {
        event.title = data.name?.toString();
      }

      fetchedEvents.push(event);
    });

    return fetchedEvents;
  }, [props.date_session,assignedWorkouts, view]);

  const timeFormatter = useCallback((valStr) => {
    const val = Number(valStr);
    const hours = Math.floor(Math.floor(val / 60) / 60);
    const minutes = Math.trunc((val % 3600) / 60);
    const seconds = Math.trunc(val % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }, []);


  const handleBack = useCallback(() => {
    setId("");
    setInsights(false);
  }, []);

  useEffect(() => {
    setId("");
    setInsights(false);
  }, []);

  return (
    <>
      {insights ? (
        <WithCache
          tables={["default_positions"]}
          component={(cached) => (
            <CoachSessionViewPreload
              sessionId={id}
              defaultPositions={cached.default_positions}
            />
          )}
        />
      ) : (
        <Grid
          sx={{
            padding: "1em",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              background: "#201B20",
              borderRadius: "10px",
              padding: "0.1em",
            }}
          >
            <Box p={1}>
              <Actions
                date={date}
                onNext={handleDateNext}
                onPrevious={handleDatePrev}
                onToday={handleDateToday}
                changeView={changeView}
                view={view}
              />
            </Box>
            <Divider />
            <Grid container sx={{paddingRight: '1rem'}}>
              <Grid
                item
                xs={
                  view === "dayGridWeek"
                    ? 12
                    : view === "multiMonthYear"
                    ? 12
                    : 10
                }
              >
                <FullCalendarWrapper>
                  <FullCalendar
                    allDayMaintainDuration
                    initialDate={date}
                    initialView={view}
                    eventDisplay="block"
                    eventColor="transparent"
                    events={eventDates}
                    eventClick={(info) => {
                      if (
                        info.view.type === "dayGridMonth" ||
                        info.view.type === "dayGridWeek"
                      ) {
                        if (info.event.extendedProps.type === "session") {
                          setInsights(true);
                          setId(info.event.id);
                        } else {
                          navigation(
                            `assigned-workout/${info.event.id.replace(
                              "workout-",
                              "",
                            )}`,
                          );
                        }
                      } else if (info.view.type === "multiMonthYear") {
                        const eventDate = info.event.start;
                        info.view.calendar.gotoDate(eventDate);
                        info.view.calendar.changeView("dayGridMonth");
                        setDate(eventDate);
                        setView("dayGridMonth");
                      }
                    }}
                    dayHeaderFormat={({ date }) => {
                      return moment(date).format("ddd");
                    }}
                    dayCellContent={(arg) => {
                      if (arg.view.type === "dayGridWeek") {
                        const dayOfMonth = arg.date.getDate();
                        return (
                          <div>
                            <div
                              className="day-number"
                              style={{ fontSize: "11px" }}
                            >
                              {dayOfMonth}
                            </div>
                          </div>
                        );
                      }

                      if (arg.view.type === "dayGridMonth") {
                        return (
                          <div
                            className="day-number"
                            style={{ fontSize: "11px", display: "flex", width: "100%", justifyContent: "space-between" }}
                          >
                            <span>{arg.dayNumberText}</span>
                            <img
                              onClick={() => setSelectedDayToAssignWorkout(arg.date)}
                              src={plusIcon}
                              style={{zIndex: 10, cursor: "pointer"}}
                            />
                          </div>
                        );
                      }
                      return arg.dayNumberText;
                    }}
                    eventDidMount={(info) => {
                      const eventContainer = info.el.querySelector(
                        ".fc-event-title-container",
                      ) as HTMLElement;
                      if (
                        (eventContainer && info.view.type === "dayGridMonth") ||
                        info.view.type === "dayGridWeek"
                      ) {
                        eventContainer.style.backgroundColor = "#342C35";
                      }

                      const eventTitle = info.el.querySelector(
                        ".fc-event-title",
                      ) as HTMLElement;

                      if (eventTitle) {
                        if (
                          (eventTitle && info.view.type === "dayGridMonth") ||
                          info.view.type === "dayGridWeek"
                        ) {
                          eventTitle.style.marginTop = "1px";
                        }
                        const iconElement = document.createElement("img");
                        iconElement.alt =
                          info.event.extendedProps.type === "session"
                            ? "Cyclist Icon"
                            : info.event.extendedProps.type === "coach workout"
                            ? "Coach Workout Icon"
                            : "Athlete Workout Icon";
                        iconElement.src =
                          info.event.extendedProps.type === "session"
                            ? cyclistSession
                            : info.event.extendedProps.type === "coach workout"
                            ? coachWorkoutIcon
                            : workoutIcon;
                        iconElement.style.width = "11px";
                        iconElement.style.marginRight = "5px";
                        iconElement.style.marginLeft = "5px";

                        if (info.el.classList.contains("fc-day-past")) {
                          iconElement.style.opacity = "0.5";
                        }

                        if (info.view.type === "multiMonthYear") {
                          iconElement.src =
                            info.event.extendedProps.type === "coach workout"
                              ? workoutLine
                              : sessionLine;
                          iconElement.style.width = "35px";
                        }

                        eventTitle.prepend(iconElement);

                        if (
                          info.view.type === "dayGridMonth" ||
                          info.view.type === "dayGridWeek"
                        ) {
                          const tssContainer = document.createElement("div");
                          tssContainer.style.display = "flex";
                          tssContainer.style.marginLeft = "20px";

                          const tssLabel = document.createElement("div");
                          tssLabel.textContent = "TSS®";
                          tssLabel.style.color = "#64605D";
                          tssLabel.style.textAlign = "center";
                          tssLabel.style.fontFamily = "DejaVu Sans";
                          tssLabel.style.fontSize = "9px";
                          tssLabel.style.marginRight = "5px";

                          const tssValue = document.createElement("div");
                          tssValue.textContent = info.event.extendedProps.tss;
                          tssValue.style.color =
                            info.event.extendedProps.type === "coach workout"
                              ? "#DD4F4A"
                              : "#D99152";
                          tssValue.style.textAlign = "center";
                          tssValue.style.fontFamily = "DejaVu Sans";
                          tssValue.style.fontSize = "9px";
                          tssValue.style.fontWeight = "700";
                          tssValue.style.marginRight = "5px";

                          tssContainer.appendChild(tssLabel);
                          tssContainer.appendChild(tssValue);

                          eventTitle.appendChild(tssContainer);
                        }
                      }
                    }}
                    dayMaxEvents={view === "dayGridMonth" ? 2 : 8}
                    fixedWeekCount={false}
                    displayEventTime={false}
                    headerToolbar={false}
                    height={
                      view === "dayGridMonth" || view === "multiMonthYear"
                        ? "auto"
                        : view === "dayGridWeek" && window.innerWidth < 800
                        ? "280px"
                        : "450px"
                    }
                    ref={calendarRef}
                    rerenderDelay={10}
                    weekends
                    weekNumberCalculation={"ISO"}
                    multiMonthMaxColumns={3}
                    multiMonthMinWidth={250}
                    moreLinkClick={
                      view === "multiMonthYear" ? "dayGridMonth" : "popover"
                    }
                    moreLinkContent={(args) => {
                      return "+" + args.num + "  more";
                    }}
                    moreLinkDidMount={(info) => {
                      if (info.view.type === "multiMonthYear") {
                        info.el.title = "Show Month";
                      } else if (info.view.type === "dayGridMonth") {
                        info.el.title = "Show All Events";
                      }
                    }}
                    showNonCurrentDates={true}
                    plugins={[
                      dayGridPlugin,
                      interactionPlugin,
                      multiMonthPlugin,
                    ]}
                  />
                </FullCalendarWrapper>
              </Grid>

              {view === "dayGridMonth" && (
                <Grid
                  item
                  xs={2}
                  sx={{
                    paddingTop: "1px",
                    paddingRight: "10px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "11px",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body1">Totals</Typography>
                  </Box>
                  {resultObject.map((result, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      sx={{
                        border: "1px solid #64605D",
                        borderLeft: "2px",
                        height: "117px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                      pl={"1em"}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                        }}
                      >
                        {!isMobileScreen && (
                          <Typography fontSize={"12px"} fontWeight={400}>
                            Distance:
                          </Typography>
                        )}

                        <Typography fontSize={"12px"}>
                          {units === "metric"
                            ? `${result.total_distance.toFixed(2) ?? "0"} km`
                            : `${
                                result.total_distance
                                  ? convert(result.total_distance)
                                      .from("km")
                                      .to("mi")
                                      .toFixed(2)
                                  : "0"
                              } mi`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                        }}
                      >
                        {!isMobileScreen && (
                          <Typography fontSize={"12px"} fontWeight={400}>
                            Time:
                          </Typography>
                        )}
                        <Typography fontSize={"12px"}>
                          {timeFormatter(result.total_time / 1000)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontSize={"12px"} fontWeight={400}>
                          TSS®:
                        </Typography>
                        <Typography fontSize={"10px"}>
                          {result.tss?.toFixed(1)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <AssignWorkoutModal athleteId={props.athleteId} open={selectedDayToAssignWorkout !== undefined} date={selectedDayToAssignWorkout} onCancel={() => setSelectedDayToAssignWorkout(undefined)} onApply={(assignedWorkout) => setAssignedWorkouts([...assignedWorkouts, assignedWorkout])}/>
        </Grid>
      )}
    </>
  );
}
