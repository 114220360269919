import {
	Avatar,
	Box,
	Button,
	Grid,
	LinearProgress,
	Stack,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "src/components/Authenticated";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import AccountSetup from "../../assets/img/views/badges/general/accountSetup.svg";
import FirstSession from "../../assets/img/views/badges/general/firstSession.svg";
import Distance20 from "../../assets/img/views/badges/distance/distance20.svg";
import Distance50 from "../../assets/img/views/badges/distance/distance50.svg";
import Distance100 from "../../assets/img/views/badges/distance/distance100.svg";
import Distance150 from "../../assets/img/views/badges/distance/distance150.svg";
import Distance200 from "../../assets/img/views/badges/distance/distance200.svg";
import Climb250 from "../../assets/img/views/badges/climb/climb250.svg";
import Climb500 from "../../assets/img/views/badges/climb/climb500.svg";
import Climb750 from "../../assets/img/views/badges/climb/climb750.svg";
import Climb1000 from "../../assets/img/views/badges/climb/climb1000.svg";
import Climb1500 from "../../assets/img/views/badges/climb/climb1500.svg";
import Climb2000 from "../../assets/img/views/badges/climb/climb2000.svg";
import Climb2500 from "../../assets/img/views/badges/climb/climb2500.svg";
import Climb3000 from "../../assets/img/views/badges/climb/climb3000.svg";
import Duration30Mins from "../../assets/img/views/badges/duration/duration30mins.svg";
import Duration1Hour from "../../assets/img/views/badges/duration/duration1hr.svg";
import Duration2Hour from "../../assets/img/views/badges/duration/duration2hr.svg";
import Duration3Hour from "../../assets/img/views/badges/duration/duration3hr.svg";
import Duration4Hour from "../../assets/img/views/badges/duration/duration4hr.svg";
import MasterTT from "../../assets/img/views/badges/posture/masterTT.svg";
import ProTT from "../../assets/img/views/badges/posture/proTT.svg";
import EliteTT from "../../assets/img/views/badges/posture/eliteTT.svg";
import MasterComfortable from "../../assets/img/views/badges/posture/masterComfort.svg";
import ProComfortable from "../../assets/img/views/badges/posture/proComfort.svg";
import EliteComfortable from "../../assets/img/views/badges/posture/eliteComfort.svg";
import MasterAggressive from "../../assets/img/views/badges/posture/masterAggressive.svg";
import ProAggressive from "../../assets/img/views/badges/posture/proAggressive.svg";
import EliteAggressive from "../../assets/img/views/badges/posture/eliteAggressive.svg";
import MasterStanding from "../../assets/img/views/badges/posture/masterStanding.svg";
import ProStanding from "../../assets/img/views/badges/posture/proStanding.svg";
import EliteStanding from "../../assets/img/views/badges/posture/eliteStanding.svg";
import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import BadgeCard from "../customComponents/BadgeCard";
import { useTitle } from "../customLayouts/DashboardLayout";
import LastMedal from "../customComponents/LastMedal";

const levels = [
	{ level: 1, min: 0, max: 99 },
	{ level: 2, min: 100, max: 299 },
	{ level: 3, min: 300, max: 599 },
	{ level: 4, min: 600, max: 999 },
	{ level: 5, min: 1000, max: 1499 },
	{ level: 6, min: 1500, max: 2099 },
	{ level: 7, min: 2100, max: 2799 },
	{ level: 8, min: 2800, max: 3599 },
	{ level: 9, min: 3600, max: 4499 },
	{ level: 10, min: 4500, max: Number.POSITIVE_INFINITY },
];

const images = [
	{ id: "Account Setup", image: AccountSetup },
	{ id: "First Session", image: FirstSession },
	{
		id: "Distance_1",
		image: Distance20,
	},
	{ id: "Distance_2", image: Distance50 },
	{ id: "Distance_3", image: Distance100 },
	{ id: "Distance_4", image: Distance150 },
	{ id: "Distance_5", image: Distance200 },
	{ id: "Climb_1", image: Climb250 },
	{ id: "Climb_2", image: Climb500 },
	{ id: "Climb_3", image: Climb750 },
	{ id: "Climb_4", image: Climb1000 },
	{ id: "Climb_5", image: Climb1500 },
	{ id: "Climb_6", image: Climb2000 },
	{ id: "Climb_7", image: Climb2500 },
	{ id: "Climb_8", image: Climb3000 },
	{ id: "Duration_1", image: Duration30Mins },
	{ id: "Duration_2", image: Duration1Hour },
	{ id: "Duration_3", image: Duration2Hour },
	{ id: "Duration_4", image: Duration3Hour },
	{ id: "Duration_5", image: Duration4Hour },
	{ id: "Master_TT", image: MasterTT },
	{ id: "Pro_TT", image: ProTT },
	{ id: "Elite_TT", image: EliteTT },
	{ id: "Master_Comfortable", image: MasterComfortable },
	{ id: "Pro_Comfortable", image: ProComfortable },
	{ id: "Elite_Comfortable", image: EliteComfortable },
	{ id: "Master_Aggressive", image: MasterAggressive },
	{ id: "Pro_Aggressive", image: ProAggressive },
	{ id: "Elite_Aggressive", image: EliteAggressive },
	{ id: "Master_Standing", image: MasterStanding },
	{ id: "Pro_Standing", image: ProStanding },
	{ id: "Elite_Standing", image: EliteStanding },
];

export default function BadgesView() {
	const user = useUser();
	const supabase = useSupabase();
	const [, setTitle] = useTitle();

	useEffect(() => {
		setTitle("Medals");
	}, []);

	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const athletePointsPromise = useCallback(
		() => supabase.from("athlete").select("badge_points"),
		[supabase],
	);

	const athletePointsCall =
		useAsyncState<SupabaseCall<typeof athletePointsPromise>>();

	useEffect(() => {
		if (isUnloaded(athletePointsCall)) {
			athletePointsCall.fire(async () => athletePointsPromise());
		}
	}, [athletePointsCall, athletePointsPromise]);

	// Edge function to get all the lists of badges

	const badgesListPromise = useCallback(
		() => supabase.functions.invoke("get-badges"),
		[supabase],
	);

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const badgesListCall = useAsyncState<any>();

	useEffect(() => {
		if (isUnloaded(badgesListCall)) {
			badgesListCall.fire(async () => badgesListPromise());
		}
	}, [badgesListPromise, badgesListCall]);

	// General Badges List
	const generalBadges = useMemo(() => {
		if (isFulfilled(badgesListCall))
			return badgesListCall.result.data.generalBadges;
	}, [badgesListCall]);

	// Distance Badges List
	const distanceBadges = useMemo(() => {
		if (isFulfilled(badgesListCall))
			return badgesListCall.result.data.distanceBadges;
	}, [badgesListCall]);

	// Climb Badges List
	const climbBadges = useMemo(() => {
		if (isFulfilled(badgesListCall))
			return badgesListCall.result.data.climbBadges;
	}, [badgesListCall]);

	// Time Based Badges List
	const durationBadges = useMemo(() => {
		if (isFulfilled(badgesListCall))
			return badgesListCall.result.data.durationBadges;
	}, [badgesListCall]);

	// Posture Badges List
	const postureBadges = useMemo(() => {
		if (isFulfilled(badgesListCall))
			return badgesListCall.result.data.postureBadges;
	}, [badgesListCall]);

	const generalBadgeIds = useMemo(() => {
		return generalBadges?.map((badge) => badge.id);
	}, [generalBadges]);

	const distanceBadgeIds = useMemo(() => {
		return distanceBadges?.map((badge) => badge.id);
	}, [distanceBadges]);

	const climbBadgeIds = useMemo(() => {
		return climbBadges?.map((badge) => badge.id);
	}, [climbBadges]);

	const durationBadgesIds = useMemo(() => {
		return durationBadges?.map((badge) => badge.id);
	}, [durationBadges]);

	const postureBadgesIds = useMemo(() => {
		return postureBadges?.map((badge) => badge.id);
	}, [postureBadges]);

	const generalBadgeListPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*")
				.in("badge_id", generalBadgeIds)
				.order("badge_id", { ascending: true })
				.eq("athlete_id", user.id)
				.then((athleteBadgesRes) => {
					const ownedBadgeIds = athleteBadgesRes.data.map(
						(athleteBadge) => athleteBadge.badge_id,
					);
					const combinedData = generalBadges.map((badge) => {
						const isOwned = ownedBadgeIds.includes(badge.id);
						const athleteBadge = athleteBadgesRes.data.find(
							(athleteBadge) => athleteBadge.badge_id === badge.id,
						);
						const image = images.find((obj) => obj.id === badge.id).image;

						return {
							...badge,
							...athleteBadge,
							own: isOwned,
							image,
						};
					});

					return combinedData;
				}),
		[supabase, generalBadges, generalBadgeIds, images],
	);

	const generalBadgeListCall =
		useAsyncState<SupabaseCall<typeof generalBadgeListPromise>>(); // prettier-ignore

	const distanceBadgeListPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*")
				.in("badge_id", distanceBadgeIds)
				.order("badge_id", { ascending: true })
				.eq("athlete_id", user.id)
				.then((athleteBadgesRes) => {
					const ownedBadgeIds = athleteBadgesRes.data.map(
						(athleteBadge) => athleteBadge.badge_id,
					);
					const combinedData = distanceBadges.map((badge) => {
						const isOwned = ownedBadgeIds.includes(badge.id);
						const athleteBadge = athleteBadgesRes.data.find(
							(athleteBadge) => athleteBadge.badge_id === badge.id,
						);
						const image = images.find((obj) => obj.id === badge.id).image;

						return {
							...badge,
							...athleteBadge,
							own: isOwned,
							image,
						};
					});

					return combinedData;
				}),
		[supabase, distanceBadges, distanceBadgeIds, images],
	);

	const distanceBadgeListCall =
		useAsyncState<SupabaseCall<typeof distanceBadgeListPromise>>(); // prettier-ignore

	const climbBadgeListPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*")
				.in("badge_id", climbBadgeIds)
				.order("badge_id", { ascending: true })
				.eq("athlete_id", user.id)
				.then((athleteBadgesRes) => {
					const ownedBadgeIds = athleteBadgesRes.data.map(
						(athleteBadge) => athleteBadge.badge_id,
					);
					const combinedData = climbBadges.map((badge) => {
						const isOwned = ownedBadgeIds.includes(badge.id);
						const athleteBadge = athleteBadgesRes.data.find(
							(athleteBadge) => athleteBadge.badge_id === badge.id,
						);
						const image = images.find((obj) => obj.id === badge.id).image;

						return {
							...badge,
							...athleteBadge,
							own: isOwned,
							image,
						};
					});

					return combinedData;
				}),
		[supabase, climbBadges, climbBadgeIds, images],
	);

	const climbBadgeListCall =
		useAsyncState<SupabaseCall<typeof climbBadgeListPromise>>(); // prettier-ignore

	// Duration Call
	const durationBadgeListPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*")
				.in("badge_id", durationBadgesIds)
				.order("badge_id", { ascending: true })
				.eq("athlete_id", user.id)
				.then((athleteBadgesRes) => {
					const ownedBadgeIds = athleteBadgesRes.data.map(
						(athleteBadge) => athleteBadge.badge_id,
					);
					const combinedData = durationBadges.map((badge) => {
						const isOwned = ownedBadgeIds.includes(badge.id);
						const athleteBadge = athleteBadgesRes.data.find(
							(athleteBadge) => athleteBadge.badge_id === badge.id,
						);
						const image = images.find((obj) => obj.id === badge.id).image;

						return {
							...badge,
							...athleteBadge,
							own: isOwned,
							image,
						};
					});
					return combinedData;
				}),
		[supabase, durationBadges, durationBadgesIds, images],
	);

	const durationBadgeListCall =
		useAsyncState<SupabaseCall<typeof durationBadgeListPromise>>(); // prettier-ignore

	// Posture Call
	const postureBadgeListPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*")
				.in("badge_id", postureBadgesIds)
				.order("badge_id", { ascending: true })
				.eq("athlete_id", user.id)
				.then((athleteBadgesRes) => {
					const ownedBadgeIds = athleteBadgesRes.data.map(
						(athleteBadge) => athleteBadge.badge_id,
					);
					const combinedData = postureBadges.map((badge) => {
						const isOwned = ownedBadgeIds.includes(badge.id);
						const athleteBadge = athleteBadgesRes.data.find(
							(athleteBadge) => athleteBadge.badge_id === badge.id,
						);
						const image = images.find((obj) => obj.id === badge.id).image;

						return {
							...badge,
							...athleteBadge,
							own: isOwned,
							image,
						};
					});
					return combinedData;
				}),
		[supabase, postureBadges, postureBadgesIds, images],
	);

	const postureBadgeListCall =
		useAsyncState<SupabaseCall<typeof postureBadgeListPromise>>(); // prettier-ignore

	// Last Medal
	const lastMedalPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*")
				.order("date_awarded", { ascending: false })
				.eq("athlete_id", user.id)
				.limit(1)
				.single()
				.then((res) => {
					const badgeTable = res.data.badge_id.includes("Distance")
						? distanceBadges
						: res.data.badge_id.includes("Climb")
							? climbBadges
							: res.data.badge_id.includes("Duration")
								? durationBadges
								: res.data.badge_id.includes("Account") ||
										res.data.badge_id.includes("Session")
									? generalBadges
									: postureBadges;
					const badgeInfo = badgeTable.find(
						(badge) => badge.id === res.data.badge_id,
					);
					const image = images.find((obj) => obj.id === badgeInfo.id).image;
					return {
						...res.data,
						...badgeInfo,
						image,
					};
				}),
		[
			supabase,
			distanceBadges,
			climbBadges,
			durationBadges,
			postureBadges,
			images,
		],
	);

	const lastMedalCall = useAsyncState<SupabaseCall<typeof lastMedalPromise>>(); // prettier-ignore

	useEffect(() => {
		if (
			isFulfilled(badgesListCall) &&
			isUnloaded(generalBadgeListCall) &&
			isUnloaded(distanceBadgeListCall) &&
			isUnloaded(climbBadgeListCall) &&
			isUnloaded(durationBadgeListCall) &&
			isUnloaded(postureBadgeListCall) &&
			isUnloaded(lastMedalCall)
		) {
			generalBadgeListCall.fire(async () => generalBadgeListPromise());
			distanceBadgeListCall.fire(async () => distanceBadgeListPromise());
			climbBadgeListCall.fire(async () => climbBadgeListPromise());
			durationBadgeListCall.fire(async () => durationBadgeListPromise());
			postureBadgeListCall.fire(async () => postureBadgeListPromise());
			lastMedalCall.fire(async () => lastMedalPromise());
		}
	}, [
		generalBadgeListCall,
		distanceBadges,
		distanceBadgeIds,
		badgesListCall,
		distanceBadgeListCall,
		climbBadgeListCall,
		durationBadgeListCall,
		postureBadgeListCall,
		lastMedalCall,
	]);

	//For Avatar we fetch name

	const profileSettingsPromise = useCallback(
		() =>
			supabase
				.from("athlete")
				.select("name, image")
				.eq("user_id", user.id)
				.then((res) => res.data[0]),
		[supabase],
	);

	const storedProfileSettingsCall =
		useAsyncState<SupabaseCall<typeof profileSettingsPromise>>();

	// Loading effect
	useEffect(() => {
		if (isUnloaded(storedProfileSettingsCall)) {
			storedProfileSettingsCall.fire(async () => profileSettingsPromise());
		}
	}, [storedProfileSettingsCall, profileSettingsPromise]);

	const avatar = useMemo(() => {
		if (isFulfilled(storedProfileSettingsCall)) {
			if (storedProfileSettingsCall.result.image) {
				return supabase.storage
					.from("avatars")
					.getPublicUrl(storedProfileSettingsCall.result.image, {
						transform: {
							width: 50,
							height: 50,
						},
					}).data.publicUrl;
			}

			return createAvatar(initials, {
				size: 30,
				radius: 55,
				seed: storedProfileSettingsCall.result.name,
			}).toDataUriSync();
		}
	}, [storedProfileSettingsCall]);

	const readBadgesPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.update({ read: true })
				.eq("read", false)
				.eq("athlete_id", user.id),
		[supabase],
	);

	const readBadgesCall =
		useAsyncState<SupabaseCall<typeof readBadgesPromise>>(); // prettier-ignore

	useEffect(() => {
		if (isUnloaded(readBadgesCall)) {
			readBadgesCall.fire(async () => readBadgesPromise());
		}
	}, [readBadgesCall, readBadgesPromise]);

	const level = useMemo(() => {
		if (isFulfilled(athletePointsCall))
			return levels.find(
				(l) =>
					athletePointsCall.result.data[0].badge_points >= l.min &&
					athletePointsCall.result.data[0].badge_points <= l.max,
			).level;
	}, [athletePointsCall]);

	const totalSessionsPromise = useCallback(
		() =>
			supabase
				.from("session")
				.select("*", { count: "exact" })
				.eq("athlete_id", user.id),
		[supabase],
	);

	const totalSessionsCall =
		useAsyncState<SupabaseCall<typeof totalSessionsPromise>>(); // prettier-ignore

	useEffect(() => {
		if (isUnloaded(totalSessionsCall)) {
			totalSessionsCall.fire(async () => totalSessionsPromise());
		}
	}, [totalSessionsCall, totalSessionsPromise]);

	const totalMedalsPromise = useCallback(
		() =>
			supabase
				.from("athlete_badges")
				.select("*", { count: "exact" })
				.eq("athlete_id", user.id),
		[supabase],
	);

	const totalMedalsCall =
		useAsyncState<SupabaseCall<typeof totalMedalsPromise>>(); // prettier-ignore

	useEffect(() => {
		if (isUnloaded(totalMedalsCall)) {
			totalMedalsCall.fire(async () => totalMedalsPromise());
		}
	}, [totalMedalsCall, totalMedalsPromise]);

	const nextLevel = useMemo(() => {
		if (isFulfilled(athletePointsCall)) {
			const athletePoints = athletePointsCall.result.data[0].badge_points; // your current points
			const currentLevelData = levels[level - 1];

			const nextLevelPoints = levels[level - 1].max - athletePoints + 1;

			const currentLevelPoints = athletePoints - currentLevelData.min; // points earned in this level
			const levelRange = currentLevelData.max - currentLevelData.min; // total range for this level

			const progressPercentage = (currentLevelPoints / levelRange) * 100;

			return {
				progressPercentage,
				nextLevelPoints,
			};
		}
	}, [athletePointsCall, level]);

	return (
		<>
			{isFulfilled(generalBadgeListCall) &&
				isFulfilled(distanceBadgeListCall) &&
				isFulfilled(climbBadgeListCall) &&
				isFulfilled(durationBadgeListCall) &&
				isFulfilled(postureBadgeListCall) &&
				isFulfilled(storedProfileSettingsCall) &&
				isFulfilled(athletePointsCall) &&
				isFulfilled(totalSessionsCall) &&
				isFulfilled(totalMedalsCall) && (
					<Box
						sx={{
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
							display: "flex",
							flexDirection: "column",
							mt: "32px",
							ml: "32px",
							mr: "32px",
							pb: "8px",
						}}
					>
						{/* Small Orange Box */}
						<Box
							sx={{
								borderTopLeftRadius: "10px",
								borderTopRightRadius: "10px",
								backgroundColor: "#BC6D29",
								height: "48px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Stack flexDirection={"row"}>
								<Typography variant="h4" sx={{ fontSize: 14 }}>
									TOTAL POINTS
								</Typography>
								<Typography noWrap>&nbsp;</Typography>
								<Typography variant="h2">
									{athletePointsCall.result.data[0].badge_points}
								</Typography>
							</Stack>
						</Box>
						{/* Overview Box */}
						<Box
							sx={{
								backgroundColor: "#262026",
								justifyContent: { sm: "space-between", xs: "center" },
								display: "flex",
								// height: "208px",
								flexDirection: {
									lg: "row",
									md: "row",
									xs: "column",
								},
							}}
						>
							{/* Left Side */}
							<Box
								sx={{
									marginLeft: "35px",
									display: "flex",
									flexDirection: "row",
									marginY: { xs: "8px", md: "75px" },
								}}
							>
								<img
									src={avatar}
									alt="avatar"
									style={{
										borderRadius: "50%",
										width: "88px",
										height: "88px",
										objectFit: "cover",
									}}
								/>
								<Stack sx={{ marginLeft: "30px", gap: "8px" }}>
									<Typography>
										{storedProfileSettingsCall.result.name}
									</Typography>

									<Stack flexDirection={"row"}>
										<Typography
											variant="h4"
											sx={{ fontWeight: 400, fontSize: "14px" }}
										>
											Total Medals:
										</Typography>
										<Typography noWrap>&nbsp;</Typography>
										<Typography
											variant="h4"
											sx={{
												color: "#DD4F4A",
												fontWeight: 700,
												fontSize: "14px",
											}}
										>
											{totalMedalsCall.result.count}
										</Typography>
									</Stack>
									<Stack flexDirection={"row"}>
										<Typography
											variant="h4"
											sx={{ fontWeight: 400, fontSize: "14px" }}
										>
											Total Sessions:
										</Typography>
										<Typography noWrap>&nbsp;</Typography>
										<Typography
											variant="h4"
											sx={{
												color: "#DD4F4A",
												fontWeight: 700,
												fontSize: "14px",
											}}
										>
											{totalSessionsCall.result.count}
										</Typography>
									</Stack>
								</Stack>
							</Box>

							{/* Progress Bar */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									marginY: { xs: "8px", md: "75px" },
									height: "88px",
									marginLeft: { xs: "35px", md: "0px" },
								}}
							>
								<Avatar
									sx={{
										bgcolor: "#E28E54",
										width: "88px",
										height: "88px",
										fontSize: "44px",
										color: "#F7F3F0",
									}}
								>
									{level}
								</Avatar>
								<Stack
									sx={{ marginLeft: "30px", gap: "8px", alignSelf: "center" }}
								>
									<Typography
										variant="h4"
										sx={{ fontWeight: 700, fontSize: "14px" }}
									>
										LEVEL {level}
									</Typography>

									<LinearProgress
										value={nextLevel.progressPercentage}
										variant="determinate"
										sx={{
											width: "269px",
											height: "8px",
											borderRadius: "56px",
											backgroundColor: "#434244",
											"& .MuiLinearProgress-bar": {
												background:
													"linear-gradient(90deg, #DD4F4A 33%, #D1593E 66%, #BC6D29 100%)",
											},
										}}
									/>

									<Stack flexDirection={"row"}>
										<Typography variant="body2">
											{level === 10
												? "Max Level"
												: `${nextLevel.nextLevelPoints} points to reach `}
										</Typography>
										<Typography noWrap>&nbsp;</Typography>
										<Typography variant="body1" sx={{ color: "#DD4F4A" }}>
											{level === 10 ? level : `Level ${level + 1}`}
										</Typography>
									</Stack>
								</Stack>
							</Box>

							{/* Last Medal */}
							{isFulfilled(lastMedalCall) && (
								<LastMedal
									description={lastMedalCall.result.description}
									image={lastMedalCall.result.image}
									points={lastMedalCall.result.points}
								/>
							)}
						</Box>

						{/* Badges Box */}
						<Box
							sx={{
								backgroundColor: "#201B20",
								paddingLeft: "52px",
								borderBottomRightRadius: "10px",
								borderBottomLeftRadius: "10px",
							}}
						>
							{/* Tab Menu */}
							<Box sx={{ marginY: "24px" }}>
								<Tabs
									value={value}
									onChange={handleChange}
									aria-label="badges tab"
								>
									<Tab label="All" />
									<Tab label="Earned" />
									<Tab label="Available" />
								</Tabs>
							</Box>

							{/* General Medals */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "24px",
									marginBottom: "24px",
									maxWidth: "844px",
								}}
							>
								<Typography color="#DD4F4A" variant="h1" fontSize={"28px"}>
									First Medals
								</Typography>
								<Grid
									container
									gap={1}
									sx={{
										width: { md: "60%", sm: "100%" },
									}}
								>
									{generalBadgeListCall.result
										.filter((badge) => {
											if (value === 0) return true;
											if (value === 1) return badge.own;
											if (value === 2) return !badge.own;
										})
										.map((badge) => (
											<Grid item key={badge.id}>
												<BadgeCard
													description={badge.description}
													id={badge.id}
													points={badge.points}
													own={badge.own}
													image={badge.image}
													session_id={
														badge.session_id ? badge.session_id : undefined
													}
												/>
											</Grid>
										))}
								</Grid>
							</Box>
							{/* Distance Medals */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "24px",
									marginBottom: "24px",
									maxWidth: "844px",
								}}
							>
								<Typography color="#DD4F4A" variant="h1" fontSize={"28px"}>
									Distance Medals
								</Typography>
								<Grid container gap={1}>
									{distanceBadgeListCall.result
										.filter((badge) => {
											if (value === 0) return true;
											if (value === 1) return badge.own;
											if (value === 2) return !badge.own;
										})
										.map((badge) => (
											<Grid item key={badge.id}>
												<BadgeCard
													description={badge.description}
													id={badge.id}
													points={badge.points}
													own={badge.own}
													image={badge.image}
													session_id={
														badge.session_id ? badge.session_id : undefined
													}
												/>
											</Grid>
										))}
								</Grid>
							</Box>

							{/* Climbing Medals */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "24px",
									marginBottom: "24px",
									maxWidth: "844px",
								}}
							>
								<Typography color="#DD4F4A" variant="h1">
									Climbing Medals
								</Typography>
								<Grid container gap={1}>
									{climbBadgeListCall.result
										.filter((badge) => {
											if (value === 0) return true;
											if (value === 1) return badge.own;
											if (value === 2) return !badge.own;
										})
										.map((badge) => (
											<Grid item key={badge.id}>
												<BadgeCard
													description={badge.description}
													id={badge.id}
													points={badge.points}
													own={badge.own}
													image={badge.image}
													session_id={
														badge.session_id ? badge.session_id : undefined
													}
												/>
											</Grid>
										))}
								</Grid>
							</Box>

							{/* Duration Medals */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "24px",
									marginBottom: "24px",
									maxWidth: "844px",
								}}
							>
								<Typography color="#DD4F4A" variant="h1">
									Duration Medals
								</Typography>
								<Grid container gap={1}>
									{durationBadgeListCall.result
										.filter((badge) => {
											if (value === 0) return true;
											if (value === 1) return badge.own;
											if (value === 2) return !badge.own;
										})
										.map((badge) => (
											<Grid item key={badge.id}>
												<BadgeCard
													description={badge.description}
													id={badge.id}
													points={badge.points}
													own={badge.own}
													image={badge.image}
													session_id={
														badge.session_id ? badge.session_id : undefined
													}
												/>
											</Grid>
										))}
								</Grid>
							</Box>

							{/* Posture Medals */}
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "24px",
									maxWidth: "672px",
									pb: "8px",
								}}
							>
								<Typography color="#DD4F4A" variant="h1">
									Posture Medals
								</Typography>
								<Grid container gap={1}>
									{postureBadgeListCall.result
										.sort((a, b) => {
											const priorityOrder = ["Master", "Pro", "Elite"];
											const getPriority = (badge) =>
												priorityOrder.findIndex((prefix) =>
													badge.description.startsWith(prefix),
												);

											return getPriority(a) - getPriority(b);
										})
										.filter((badge) => {
											if (value === 0) return true;
											if (value === 1) return badge.own;
											if (value === 2) return !badge.own;
										})
										.map((badge) => (
											<Grid item flexDirection={"column"} key={badge.id}>
												<BadgeCard
													description={badge.description}
													id={badge.id}
													points={badge.points}
													own={badge.own}
													image={badge.image}
													session_id={
														badge.session_id ? badge.session_id : undefined
													}
												/>
											</Grid>
										))}
								</Grid>
							</Box>
						</Box>
					</Box>
				)}
		</>
	);
}
