import { Box, Modal, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import { useNavigate } from "react-router-dom";

export type BadgeCardType = {
	description: string;
	id: number;
	own: boolean;
	points: number;
	image: string;
	date_awarded?: Date;
	athlete_id?: string;
	badge_id?: string;
	session_id?: string;
};

export default function BadgeCard(props: BadgeCardType) {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const supabase = useSupabase();
	const navigate = useNavigate();

	const sessionNamePromise = useCallback(
		() =>
			supabase
				.from("session")
				.select("recording_name")
				.eq("id", props.session_id)
				.then((res) => res.data[0]),
		[supabase, props.session_id],
	);

	const sessionNameCall =
		useAsyncState<SupabaseCall<typeof sessionNamePromise>>();

	useEffect(() => {
		if (isUnloaded(sessionNameCall) && props.session_id) {
			sessionNameCall.fire(async () => sessionNamePromise());
		}
	}, [sessionNameCall, sessionNamePromise, props.session_id]);

	return (
		<>
			<Box
				sx={{
					width: "156px",
					height: "186px",
					backgroundColor: "#2C262D",
					borderRadius: "6px",
					borderColor: "#DD4F4A",
					borderWidth: props.own ? "1px" : "0px",
					borderStyle: "solid",
					justifyContent: "space-evenly",
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					cursor: props.own ? "pointer" : "default",
				}}
				onClick={() => {
					return props.own ? handleOpen() : null;
				}}
			>
				<img
					src={props.image}
					alt={props.badge_id}
					style={{ opacity: props.own ? 1 : 0.4 }}
				/>
				<Stack direction={"column"} alignItems={"center"}>
					<Typography
						sx={{ opacity: props.own ? 1 : 0.4, textAlign: "center" }}
					>
						{props.description}
					</Typography>
					<Typography sx={{ opacity: props.own ? 1 : 0.4 }}>
						{props.points} points
					</Typography>
				</Stack>
			</Box>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableAutoFocus={true}
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "388px",
						height: "418px",
						bgcolor: "#2C262D",
						border: "2px solid #DD4F4A",
						p: 4,
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<img
						src={props.image}
						alt={props.badge_id}
						height={"204px"}
						width={"178px"}
					/>
					<Typography sx={{ mt: 2 }}>{props.description}</Typography>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Stack direction="row" spacing={2}>
							<Typography>Points Earned</Typography>
							<Typography color={"#DD4F4A"}>+{props.points}</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Typography>Date Earned</Typography>
							<Typography color={"#BC6D29"}>
								{dayjs(props.date_awarded).format("DD MMM. YYYY")}
							</Typography>
						</Stack>
						{props.session_id && isFulfilled(sessionNameCall) && (
							<Stack direction="row" spacing={2}>
								<Typography>Session</Typography>
								<Typography
									color={"#BC6D29"}
									onClick={() =>
										navigate(`/dashboard/session/${props.session_id}`)
									}
									style={{
										cursor: "pointer",
										textDecoration: "underline",
									}}
								>
									{sessionNameCall.result.recording_name}
								</Typography>
							</Stack>
						)}
					</Box>
				</Box>
			</Modal>
		</>
	);
}
