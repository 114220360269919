import AppleIcon from "@mui/icons-material/Apple";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isRejected } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import * as yup from "yup";
import "../../assets/scss/views/RegisterView.scss";
import Lottie from "lottie-react";
import logojson from "../../assets/logoGif.json";

import VerifyEmailView from "./VerifyEmailView";

export default function SignInView() {
  const supabase = useSupabase();
  const signInCall =
    useAsyncState<SupabaseCall<typeof supabase.auth.signInWithPassword>>();
  const navigate = useNavigate();
  const session = useSession();

  useEffect(() => {
    if (
      isFulfilled(signInCall) &&
      !signInCall.result.error &&
      "unload" in session
    ) {
      session.unload();
      navigate("/");
    }
  }, [signInCall]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      terms: true,
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("The email provided should be a valid email address")
        .max(255)
        .required("Email is required"),
      password: yup.string().max(255).required("Password is required"),
      terms: yup
        .boolean()
        .oneOf([true], "You must agree to our terms and conditions"),
    }),
    onSubmit: (values) => {
      if ("fire" in signInCall) {
        signInCall.fire(() =>
          supabase.auth.signInWithPassword({
            email: values.email,
            password: values.password,
          }),
        );
      }
    },
  });


  useEffect(() => {
    if (
      isRejected(signInCall) &&
      signInCall.error?.name === "AuthApiError" &&
      signInCall.error?.message === "Invalid login credentials"
    ) {
      formik.setFieldError("password", signInCall.error?.message);
      signInCall.unload();
    }
    if (
      isFulfilled(signInCall) &&
      signInCall.result.error?.name === "AuthApiError" &&
      signInCall.result.error?.message === "Invalid login credentials"
    ) {
      formik.setFieldError("password", signInCall.result.error?.message);
      signInCall.unload();
    }
  }, [signInCall, formik]);

  return (
    <>
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flex: { lg: "0 0 50%", md: "0 0 100%", xs: "0 0 100%" },
              "@media (max-width: 330px)": {
                alignItems: "flex-start",
              },
              pt: "121px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box width={"20.375rem"}>
                <Typography
                  variant="h1"
                  color="text.secondary"
                  className="headline"
                >
                  Sign In
                </Typography>
              </Box>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1.5rem",
                    mt: "1.125rem",
                  }}
                >
                  <FormControl>
                    <TextField
                      label="Email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          width: "20.5rem",
                        },
                      }}
                    />
                    {Boolean(formik.touched.email) && formik.errors.email && (
                      <FormHelperText error>
                        {formik.errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl>
                    <TextField
                      label="Password"
                      name="password"
                      type="password"
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          width: "20.5rem",
                        },
                      }}
                    />
                    {Boolean(formik.touched.password) &&
                      formik.errors.password && (
                        <FormHelperText error>
                          {formik.errors.password}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                    mt: "1.5rem",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    className="btn-width"
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Box>
              </form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                  mt: "2rem",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-width"
                  startIcon={<GoogleIcon />}
                  onClick={(_) =>
                    supabase.auth.signInWithOAuth({ provider: "google" })
                  }
                >
                  <Typography variant="h5">Sign In With Google</Typography>
                </Button>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-width"
                  startIcon={<FacebookOutlinedIcon />}
                  onClick={(_) =>
                    supabase.auth.signInWithOAuth({ provider: "facebook" })
                  }
                >
                  <Typography variant="h5">Sign In With Facebook</Typography>
                </Button> */}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="btn-width"
                  startIcon={<AppleIcon />}
                  onClick={(_) =>
                    supabase.auth.signInWithOAuth({ provider: "apple" })
                  }
                >
                  <Typography variant="h5">Sign In With Apple</Typography>
                </Button>
              </Box>

              <Box sx={{ mt: "1.9375rem", width: "20.625rem", mb: ".5rem" }}>
                <Typography
                  variant="body1"
                  sx={{ color: "#BC6D29", mb: ".5rem" }}
                  component={Link}
                  to="/account/reset-password"
                >
                  Forgot password?
                </Typography>{" "}
              </Box>
              <Box sx={{ width: "20.625rem" }}>
                <Typography sx={{ display: "inline-block" }} variant="body2">
                  Don't have an account?
                </Typography>{" "}
                <Typography
                  sx={{ display: "inline-block", color: "#BC6D29" }}
                  variant="body1"
                  component={Link}
                  to="/account/register"
                >
                  Sign up here
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "none", lg: "flex" },
              flex: "0 0 50%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#BC6D29",
              borderRadius: "2.5rem 0rem 0rem 2.5rem",
            }}
          >
            <Lottie
              animationData={logojson}
              loop={true}
              style={{ width: "600px" }}
            />
          </Box>
        </Box>
    </>
  );
}
