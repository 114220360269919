import CircleLogo from "../../assets/img/views/dashboard-widgets/circleLogo.png";
import { useEffect, useState, useMemo } from 'react';

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const interpolateColor = (color1: string, color2: string, t: number): string => {
  const parseRgb = (color: string) =>
    color.match(/\d+/g)?.map(Number) || [0, 0, 0];
  const [r1, g1, b1] = parseRgb(color1);
  const [r2, g2, b2] = parseRgb(color2);
  const r = r1 + (r2 - r1) * t;
  const g = g1 + (g2 - g1) * t;
  const b = b1 + (b2 - b1) * t;
  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
};

export const useColorInterpolation = (
  pairs: { value: number; color: string }[],
  target: number
): string =>
  useMemo(() => {
    const sorted = pairs.sort((a, b) => a.value - b.value);

    // Find the lower bound (highest value <= target)
    let lower = null;
    for (let i = sorted.length - 1; i >= 0; i--) {
      if (sorted[i].value <= target) {
        lower = sorted[i];
        break;
      }
    }

    let upper = null;
    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i].value > target) {
        upper = sorted[i];
        break;
      }
    }

    if (!lower) return sorted[0].color;
    if (!upper) return sorted[sorted.length - 1].color;

    const t = (target - lower.value) / (upper.value - lower.value);
    return interpolateColor(lower.color, upper.color, t);
  }, [pairs, target]);

const usePercentageAnimation = (targetPercentage: number, duration: number) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    let animationFrame: number;
    let isActive = true;

    const start = currentPercentage;
    const end = targetPercentage;
    const increment = (end - start) / (duration / 16.7);
    let current = start;

    const animate = () => {
      if (!isActive) return;

      current += increment;

      if ((increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        setCurrentPercentage(Math.round(end)); 
        return;
      }

      setCurrentPercentage(Math.round(current));
      animationFrame = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      isActive = false;
      cancelAnimationFrame(animationFrame);
    };
  }, [targetPercentage, duration]);

  return currentPercentage;
};

const Circle = ({ colour, pct }) => {
  const r = 75;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={colour}
      strokeWidth={"0.75rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : circ}
      strokeLinecap="round"
      style={{transition: "all 0.7s ease-in-out"}}
    ></circle>
  );
};

const CircularProgressBar = ({ percentage, colors }) => {
  const pct = cleanPercentage(percentage);
  const animatedPct = usePercentageAnimation(pct, 700);
  const interpolatedColor = useColorInterpolation(colors, pct);
  return (
    <svg height="100%" width="100%" viewBox="0 0 200 200">
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle colour={interpolatedColor} pct={pct} />
      </g>
      <image
        href={CircleLogo}
        x={32}
        y={35}
        width={135}
        height={135}
      />
      <text
        x="50%"
        y="45%"
        dominantBaseline="central"
        fill="#fff"
        textAnchor="middle"
        fontSize={"2.5em"}
        fontFamily="DejaVu Sans"
        fontStyle={"italic"}
        fontWeight={700}
      >
        {animatedPct}%
      </text>
      <text
        x="50%"
        y="56%"
        dominantBaseline="central"
        fill="#fff"
        textAnchor="middle"
        fontSize={"1em"}
        fontFamily="DejaVu Sans"
        fontWeight={400}
      >
        AEROSCORE
      </text>
    </svg>
  );
};

export default CircularProgressBar;
