import { Box, Stack, Typography } from "@mui/material";

export type LastMedalProps = {
	description: string;
	image: string;
	points: number;
};

export default function LastMedal(props: LastMedalProps) {
	return (
		<Box
			sx={{
				marginRight: "35px",
				display: "flex",
				flexDirection: "row",
				marginY: { xs: "8px", md: "75px" },
				marginLeft: { xs: "35px", md: "0px" },
			}}
		>
			<img src={props.image} alt="badge" height={"88px"} width={"88px"} />
			<Stack sx={{ marginLeft: "30px", gap: "8px" }}>
				<Typography variant="body1" sx={{ fontSize: "14px", fontWeight: 700 }}>
					Last Medal
				</Typography>
				<Typography variant="body1" sx={{ fontSize: "12px", fontWeight: 700 }}>
					{props.description}
				</Typography>
				<Stack direction={"row"}>
					<Typography
						variant="body1"
						sx={{ fontSize: "13px", fontWeight: 400 }}
					>
						Points earned
					</Typography>
					<Typography noWrap>&nbsp;</Typography>
					<Typography
						sx={{ color: "#DD4F4A", fontSize: "13px", fontWeight: 400 }}
					>
						+{props.points}
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
}
